import {Button, Fieldset, Group, SegmentedControl, Select, Stack, Textarea, TextInput} from "@mantine/core";
import {useEffect, useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {activeServersAtom} from "../atoms/adminAtoms";
import {useAtom, useAtomValue} from "jotai/index";
const webhookURL = 'https://discord.com/api/webhooks/1283535035277770752/y1ovadCbWxrAJvflyxzXtDloTcIHtKinxBXaVeGwoq3hTZrz_cwAwS4PTSNdtgSEHcoo';

export default function DiscordManagement() {
    const [serverNames, setServerNames] = useState([]);
    const [notifyTarget,setNotifyTarget] = useState("shutdown");
    const [server, setServer] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const activeServers = useAtomValue(activeServersAtom);

    useEffect(() => {
        let srvs = [];
        if(activeServers && activeServers.length > 0){
            for(let i=0; i<activeServers.length; i++){
                srvs.push(activeServers[i].ServerName);
            }
        }
        setServerNames(srvs);
    }, [activeServers]);

    async function SubmitDiscord(){
        let embed = {};
            embed = {
                color: 0x0099ff,
                title: title,
                url: 'https://play.manacaster.com',
                "thumbnail": {
                    "url": "https://play.manacaster.com/logo512.png"
                },
                author: {
                    name: "Manacaster Games",
                    icon_url: "https://play.manacaster.com/logo192.png",
                    url: 'https://play.manacaster.com',
                },
                description: "A new patch has been released and is now available at https://play.manacaster.com \n\n" + description,
                fields: [
                    { name: 'Known Issues ('  , value: "" },
                ],
                timestamp: new Date().toISOString(),
                footer: {
                    text: 'Manacaster Update',
                    iconURL: ""
                }
            };

        try {
            await axios.post(webhookURL, {username: "Manacaster Games",
                avatarURL: "https://play.manacaster.com/logo192.png",
                content: "@everyone",
                tts: false,
                embeds: [embed]
            });
        } catch (error) {
            console.error('Error sending message to Discord:', error);
            toast.error('Error occurred posting to discord webhook.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    return (
        <div className={"flex flex-row w-full h-full overflow-hidden"}>
            <div className={"flex flex-col w-1/2 h-full overflow-y-auto p-4 "}>
                <Fieldset legend="Server Notifications">
                    <Stack
                        h={300}
                        bg="var(--mantine-color-body)"
                        align="stretch"
                        justify="flex-start"
                        gap="md"
                    >
                        <Select
                            label="Related Server"
                            placeholder="Pick server"
                            data={serverNames}
                            value={server}
                            onChange={setServer}
                        />
                        <TextInput label="Title" placeholder="title" value={title} onChange={(e) => setTitle(e.currentTarget.value)} mt="md" />
                        <Textarea
                            placeholder="Description"
                            label="Description"
                            onChange={(e) => setDescription(e.currentTarget.value)}
                            value={description}
                            minRows={4}
                            autosize
                            maxRows={4}
                        />
                    </Stack>
                    <Group  justify="center" mt="md">
                        <SegmentedControl
                            value={notifyTarget}
                            onChange={setNotifyTarget}
                            data={[
                                { label: 'Server Shutdown', value: 'shutdown' },
                                { label: 'Maintenance', value: 'maintenance' },
                                { label: 'Server Online', value: 'online' },
                                { label: 'Server Update', value: 'update' },
                            ]}
                        />
                    </Group>
                    <Group justify="flex-end" mt="md">
                        <Button>Submit</Button>
                    </Group>
                </Fieldset>
            </div>
        </div>
    )
}