import React, {useEffect, useState, useMemo, memo, useCallback} from "react";
import Tooltip from "@mui/material/Tooltip";
import {useAppContext} from "../contexts/appContext";
import {useDbContext} from "../contexts/dbContext";
import {dbstyle} from "./dbstylings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {Popover} from "@mantine/core";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useAtomValue} from "jotai/index";
import {sessionAtom} from "../atoms/appAtoms";

export const Card = (props) => {
    const {card, cardsOnly, vis} = props;
    const {cardsdate, cardX, cardY} = useAppContext();
    const session = useAtomValue(sessionAtom);
    const {
        AddCard,
        RemoveCard,
        activeDeck,
        cardSize,
        IsInDeck,
        maxmain,
        maxmana,
        RemoveBench,
        AddBench,
        IsInBench,
        cardTooltips,
    } = useDbContext();

    const [contextMenu, setContextMenu] = useState(null);
    let amount = IsInDeck(card.Id, card.type === "resources");
    let benchamount = IsInBench(card.Id);
    const [hovering, setHovering] = useState(false);
    const [borderSet, setBorderSet] = useState(false);
    const [border, setBorder] = useState("")

    function returnCol(el) {
        switch (el) {
            case "Flame":
                return "amber-700";
            case "Lunar":
                return "purple-700";
            case "Metal":
                return "gray-400";
            case "Solar":
                return "yellow-500";
            case "Water":
                return "blue-500";
            case "Earth":
                return "green-600";
            default:
                return "green-600";
        }
    }

    function Colors(){
        return(
            <div className={"flex flex-col border-amber-700 border-t-amber-700 border-r-amber-700 border-l-amber-700 border-b-amber-700"}>
                <div
                    className={"flex flex-col border-purple-700 border-t-purple-700 border-r-purple-700 border-l-purple-700 border-b-purple-700"}>
                    <div
                        className={"flex flex-col border-gray-400 border-t-gray-400 border-r-gray-400 border-l-gray-400 border-b-gray-400"}>

                    </div>
                    <div className={"flex flex-col border-yellow-500 border-t-yellow-500 border-r-yellow-500 border-l-yellow-500 border-b-yellow-500"}>
                    </div>
                    <div className={"flex flex-col border-blue-500 border-t-blue-500 border-r-blue-500 border-l-blue-500 border-b-blue-500"}>
                    </div>
                    <div
                        className={"flex flex-col border-green-600 border-t-green-600 border-r-green-600 border-l-green-600 border-b-green-600"}>
                    </div>
                </div>
            </div>
        )
    }

    function SetBorder() {
        if (card.identity.includes("-")) {
            let els = card.identity.split("-");
            const str = ` border-2 border-t-${returnCol(els[0])}  border-l-${returnCol(els[0])} border-r-${returnCol(els[1])} border-b-${returnCol(els[1])}`;
            setBorder(str);
            setBorderSet(true);
        } else {
            const str = ` border-2 border-${returnCol(card.identity)}`;
            setBorder(str);
            setBorderSet(true);
        }
    }

    useEffect(() => {
       if(card) SetBorder();
    }, [card])

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const Image = () => {
        return(
            <img
                height={"100%"}
                width={"auto"}
                alt={"preview" + card.Id}
                src={`/cards/${card.Id}.png?key=${cardsdate}`}
            />
        )
    }

    const handleAdd = useCallback(() => {
        if (AddCard(card)) {
            setContextMenu(null);
        }
    }, [AddCard, card]);
    
    const handleAddBench = useCallback(() => {
        if (AddBench(card)) {
            setContextMenu(null);
        }
    }, [AddBench, card]);
    
    const handleRemove = useCallback(() => {
        if (RemoveCard(card)) {
            setContextMenu(null);
        }
    }, [RemoveCard, card]);
    
    const handleRemoveBench = useCallback(() => {
        if (RemoveBench(card)) {
            setContextMenu(null);
        }
    }, [RemoveBench, card]);

    const handleClose = () => {
        setContextMenu(null);
    };

    const size2 = () => {
        if (window.innerWidth <= 500) return 0.25;
        else return cardSize;
    }

    const size = () => {
        if (window.innerWidth <= 500) return 0.25;
        else return 0.4;
    }

    let max = card.type === "resources" ? 12 : maxmain;

    if (cardsOnly) {
        return (
            <div style={{cursor: 'context-menu', height: (cardY * size2()), width: cardX * size2()}}
                 className={(vis ? "flex " : "hidden ") +" flex-col relative select-none overflow-hidden rounded-xl items-center justify-start " + border}>
                <LazyLoadImage height={cardY * size2()} width={cardX * size2()} alt={card.Id}
                     src={"/cards/" + card.Id + ".png?key=" + cardsdate}/>
            </div>
        )
    } else {
        if (card.type === "resources") {
            return (
                <Popover width={430} position="right" opened={cardTooltips && hovering}
                         shadow="md">
                    <Popover.Target>
                        <div style={{cursor: 'context-menu', height: (cardY * size2()) + 35, width: cardX * size2()}}
                             onMouseEnter={() => {
                                 if (cardTooltips) setHovering(true);
                             }} onMouseLeave={() => {
                            if (cardTooltips) setHovering(false);
                        }} className={(vis ? "flex " : "hidden ") +" flex-col relative select-none overflow-hidden rounded-xl items-center justify-start " + (amount > 0 ? " border-4 border-[#716039] " : " border-2 border-[#716039] ")}>
                            <div style={{height: cardY * size2(), width: cardX * size2()}}>
                                <Image />
                            </div>
                            <div
                                className={"flex relative flex-row h-[30px] w-full bg-black bg-opacity-40 border-t border-[#716039] justify-between items-center px-2"}>
                                <Tooltip title={"Remove 1 from mana deck"} placement={"top"}>
                                    <div
                                        className={"flex flex-row h-8 w-10 bg-red-500 bg-opacity-20 justify-center items-center hover:bg-opacity-40"}
                                        onClick={(e) => {
                                            if (!cardsOnly && session) {
                                                RemoveCard(card);
                                            }
                                        }}
                                    >-
                                    </div>
                                </Tooltip>
                                {amount}
                                <Tooltip title={"Add 1 to mana deck"} placement={"top"}>
                                    <div
                                        className={"flex flex-row h-8 w-10 bg-green-500 bg-opacity-20 justify-center items-center hover:bg-opacity-40"}
                                        onClick={(e) => {
                                            if (!cardsOnly && session) {
                                                if (amount < maxmana) {
                                                    AddCard(card);
                                                }
                                            }
                                        }}
                                    >+
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </Popover.Target>
                    <Popover.Dropdown style={{pointerEvents: 'none', height: cardY * size() + 35, width: (cardX * size()) + 35}}>
                        {borderSet ? <div className={"flex flex-col z-50 overflow-hidden self-center justify-self-center rounded-2xl " + border} style={{height: cardY * size(), width: cardX * size(), backgroundSize: "contain",
                            backgroundImage: "url(/cards/" + card.Id + ".png?key=" + cardsdate + ")"}}>
                        </div> : <></>}
                    </Popover.Dropdown>
                </Popover>
            )
        } else
            return (
                <Popover width={430} position="right" opened={cardTooltips && hovering}
                         shadow="md">
                    <Popover.Target>
                        <div onClick={(e) => {
                            if (!cardsOnly && session && e.detail === 2) {
                                if (amount > 0) {
                                    RemoveCard(card);
                                } else {
                                    if (activeDeck.maindeck.length < 50) {
                                       AddCard(card);
                                    } else {
                                        if (!IsInBench(card.Id)) {
                                            AddBench(card);
                                            toast.error("Main deck already contains 50 cards, card placed into your bench.");
                                        } else {
                                            RemoveBench(card);
                                        }
                                    }
                                }
                            }
                        }} onContextMenu={handleContextMenu}
                             style={{
                                 cursor: 'context-menu',
                                 height: cardY * size2(),
                                 width: cardX * size2(),
                             }}
                             onMouseEnter={() => {
                                 if (cardTooltips) setHovering(true);
                             }}
                             onMouseLeave={() => {
                                 handleClose();
                                 if (cardTooltips) setHovering(false);
                             }} className={(vis ? "flex " : "hidden ") +" flex-col relative justify-start items-start select-none"}>
                            {!cardsOnly && session &&
                                <div className={"flex absolute flex-row -top-1 -right-1 h-auto w-auto z-10"}>
                                    <div
                                        className={"flex flex-row h-auto w-auto justify-end items-end text-green-500 text-lg"}>
                                        {benchamount &&
                                            <p className={"select-none text-xs font-bold bg-yellow-500 text-black flex flex-row justify-center items-center rounded-full w-6 h-6"}>
                                                B
                                            </p>}
                                        {amount > 0 &&
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                 fill="currentColor"
                                                 className="w-7 h-7">
                                                <path fillRule="evenodd"
                                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                      clipRule="evenodd"/>
                                            </svg>}
                                    </div>
                                </div>}

                            <Menu open={contextMenu !== null} onClose={handleClose} anchorReference="anchorPosition"
                                  anchorPosition={
                                      contextMenu !== null
                                          ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                                          : undefined
                                  }
                            >
                                {activeDeck && (amount < 50) && (amount < max) &&
                                    <MenuItem onClick={handleAdd}>Add</MenuItem>}
                                {activeDeck && benchamount < 1 &&
                                    <MenuItem onClick={handleAddBench}>Add Bench</MenuItem>}
                                {activeDeck && amount > 0 && <MenuItem onClick={handleRemove}>Remove</MenuItem>}
                                {activeDeck && benchamount > 0 &&
                                    <MenuItem onClick={handleRemoveBench}>Remove Bench</MenuItem>}
                            </Menu>
                            <div className={"rounded-lg " + (amount > 0 ? dbstyle.cards.selected : dbstyle.cards.notselected)} style={{height: cardY * size2(), width: cardX * size2(), backgroundSize: "contain",
                                backgroundImage: "url(/cards/" + card.Id + ".png?key=" + cardsdate + ")"}}>
                            </div>
                        </div>
                    </Popover.Target>
                    <Popover.Dropdown style={{pointerEvents: 'none', height: cardY * size() + 35, width: (cardX * size()) + 35}}>
                        {borderSet ? <div className={"flex flex-col z-50 overflow-hidden self-center justify-self-center rounded-2xl " + border} style={{height: cardY * size(), width: cardX * size(), backgroundSize: "contain",
                            backgroundImage: "url(/cards/" + card.Id + ".png?key=" + cardsdate + ")"}}>
                        </div> : <></>}
                    </Popover.Dropdown>
                </Popover>
            )
    }
};