import React, {useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from "@mui/icons-material/Send";
import {useSocial} from "../hooks/useSocial";
import {gethDateTime, supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {Chip} from "@mui/material";
import {useAtomValue} from "jotai/index";
import {profileAtom, userIdAtom} from "../atoms/appAtoms";

export default function FriendList(props) {
    const { sbOpen, setSbOpen} = props;
    const {friends, UserData, FriendOnline, selectedFriend, setSelectedFriend} = useSocial();
    const id = useAtomValue(userIdAtom);
    const profile = useAtomValue(profileAtom);

    const friendlabel = (friendentry) => {
        if(friendentry.friendid === id) return UserData(friendentry.user_id).username;
        else return UserData(friendentry.friendid).username;
    }

    async function SendChat(){
        let mess = {sendername: profile.username, time: gethDateTime(), message: text};
        let nc = selectedFriend.chat;
        nc.push(mess);
        const {error} = await supabase.from('friends')
            .update({chat: nc})
            .eq("id", selectedFriend.id)
        if(error){
            toast.error('Failed to send chat message.', {
                duration: 4000,
                position: 'bottom-right',
            });
        } else {
            setText("");
        }
    }

    const friendID = (friendentry) => {
        if(friendentry.friendid === id) return friendentry.user_id;
        else return friendentry.friendid;
    }

    const [text, setText] = useState("")
    return (
        <div className={"flex flex-row h-full w-full overflow-hidden"}>
            <div className={(sbOpen ? "flex w-1/3" : "hidden") + " flex-col h-full border-r border-[#51b78c] border-opacity-20"}>
                <div className={"flex flex-col h-20 w-full p-1 border-[#51b78c] gap-2 border-opacity-20 items-center justify-center border-b"}>
                    <div className={" h-1/3 border-b border-[#51b78c]"}>
                        Selected Friend:
                    </div>
                    <div className={"h-2/3 flex w-full flex-row text-xs font-semibold gap-2 items-center justify-center"}>
                        {selectedFriend && (FriendOnline(friendID(selectedFriend)) ?  <Chip label="Online" className={"text-xs"} size={"small"} color="primary"/> : <Chip label="Offline" color="error"/>)}
                        {selectedFriend ? friendlabel(selectedFriend) : "None"}
                    </div>
                </div>
                <div className={"flex flex-col h-4/5 w-full"}>
                    <div className={"flex flex-col w-full h-full overflow-hidden px-2 py-1"}>
                        <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                            <div className={"flex flex-col w-full h-auto"}>
                                {friends && friends.length > 0 && friends.map((el, ind) => (
                                    <div key={ind}>
                                        {el.status === "accepted" &&
                                        <Tooltip title={"Select " + friendlabel(el)} placement="right">
                                            <div className={(selectedFriend && (selectedFriend.friendid === el.friendid || selectedFriend.user_id === el.friendid) ? "bg-[#51b78c]" : "bg-[#3b6f5e26] ") + " flex flex-row px-2 justify-around cursor-pointer items-center w-full h-8 border border-[#51b78c] rounded-xl text-sm hover:opacity-75"}
                                                 onClick={() => {
                                                     setSelectedFriend(el);
                                                 }}
                                            >
                                                {FriendOnline(friendID(el)) ?
                                                    <div className={"h-5 w-5 rounded-full text-xs bg-green-600"}/> :
                                                    <div className={"h-5 w-5 rounded-full text-xs bg-red-600"}/>}
                                                <p className={"w-full justify-center items-center text-center"}>{friendlabel(el)}</p>
                                            </div>
                                        </Tooltip>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col h-full overflow-hidden " + (sbOpen ? "w-2/3" : "w-full")}>
                <div className={"flex flex-col h-full w-full py-3 lg:py-2 px-5 lg:px-3 overflow-y-auto"}>
                    <div className={"flex flex-col w-full h-12 mb-2 justify-center items-center gap-1 border-b border-[#51b78c] border-opacity-20"}>
                        <div className={"flex flex-col h-auto w-full hover:opacity-95"}>
                            {selectedFriend ? <div className={"inline-block break-words h-auto w-full text-[#28ffa2] overflow-hidden"}>
                                Conversation with {friendlabel(selectedFriend)}
                            </div> :
                                <div>Open a conversation with a friend in the sidebar.</div>}
                        </div>
                    </div>
                    {selectedFriend && selectedFriend.chat.length > 0 && selectedFriend.chat.map((el, ind) =>
                        <ChatMessage key={ind} entry={el} />
                    )}
                </div>
                <div className={"flex flex-col h-24 w-full border-t gap-1 border-[#51b78c] border-opacity-40 p-2"}>
                    <div className={"flex flex-row h-1/3 w-full"}>

                    </div>
                    {selectedFriend &&    <div className={"flex flex-row h-2/3 gap-1 w-full justify-center items-center"}>
                        <input aria-multiline={true} value={text} onChange={e => setText(e.target.value)}
                               onKeyDown={(e) => {
                                   if(e.key === "Enter") {
                                       setText(e.currentTarget.value);
                                       SendChat();
                                   }
                               }}
                               className={"bg-[#244939] border border-[#51b78c] h-10 w-full justify-center items-center"}/>
                        <div className={"flex flex-row w-14 h-10 justify-center items-center"}>
                            <Tooltip title="Send Message" placement="bottom">
                                <div
                                    className={"bg-[#51b78c] flex flex-row h-10 w-12 border border-[#51b78c] rounded-xl cursor-pointer hover:opacity-75 items-center justify-center"}
                                    onClick={() => {
                                        SendChat();
                                    }}
                                >
                                    <SendIcon />
                                </div>
                            </Tooltip>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

function ChatMessage(props) {
    const {entry} = props;
    return (
        <div className={"flex flex-col h-auto w-full hover:opacity-95"}>
            <div className={"flex flex-row gap-2 h-auto w-auto"}>
                <p className={"flex flex-row h-auto w-auto text-[#51b78c] text-lg font-semibold"}>{entry.sendername}</p>
                <p className={"flex flex-row h-auto w-auto text-[#177550] text-xs justify-start items-start"}>{entry.time}</p>
            </div>

            <div className={"inline-block break-words h-auto w-full text-[#28ffa2] overflow-hidden"}>
            {entry.message}
            </div>
        </div>
    )
}