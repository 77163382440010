import { createContext, useContext } from 'react';

const AppContext = createContext({
  cardX: 814,
  cardY: 1110,
  cardsdate: "031124",
  version: "0.1.5b10",
  company: "Manacaster Games", 
  product: "Manacaster"
});

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const constants = {
    cardX: 814,
    cardY: 1110,
    cardsdate: "031124",
    version: "0.1.5b10",
    company: "Manacaster Games",
    product: "Manacaster"
  };

  return (
    <AppContext.Provider value={constants}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;