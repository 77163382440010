import {atom} from 'jotai';
import {atomWithDefault} from 'jotai/utils';
import {userIdAtom} from './appAtoms';

export const reportsAtom = atom([]);
export const loadingRMAtom = atom(true);
export const activeReportAtom = atom(null);
export const loadingStatsAtom = atom(true);
export const newReportsAtom = atom([]);

export const cardStatsAtom = atom({
  rcardsDeployed: [],
  actionsDeployed: [],
  unitDeployed: []
});

export const cardTotalStatsAtom = atom({
  rcardsDeployed: [],
  actionsDeployed: [],
  unitDeployed: []
});

export const winsAtom = atom(0);
export const lossesAtom = atom(0);
export const totalPlayedAtom = atom(0);
export const activeSortAtom = atom("descTime");
export const activeTypeAtom = atom("all");

// Derived atom for sorted reports
export const sortedStatsReportsAtom = atomWithDefault((get) => {
  const reports = get(reportsAtom);
  const activeSort = get(activeSortAtom);
  const activeType = get(activeTypeAtom);
  const userId = get(userIdAtom);

  if (!reports) return [];

  let filteredReports = [...reports];

  switch (activeType) {
    case "wins":
      filteredReports = filteredReports.filter(report => report.winnerID === userId);
      break;
    case "losses":
      filteredReports = filteredReports.filter(report => report.winnerID !== userId);
      break;
    case "draftpvp":
      filteredReports = filteredReports.filter(report => report.matchtype === "DraftPvP");
      break;
    case "casual":
      filteredReports = filteredReports.filter(report => report.matchtype === "Casual");
      break;
    case "all":
    default:
      break;
  }

  switch (activeSort) {
    case "ascTime":
      return filteredReports.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? -1 : 1);
    case "descTime":
      return filteredReports.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? 1 : -1);
    default:
      return filteredReports;
  }
});