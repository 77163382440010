import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import React, {useEffect} from "react";
import {useApp} from "../hooks/useApp";

export default function InGame(props){
    const {session, loadingInitial} = useApp();
    const {acctoken, reqtoken} = props;

    useEffect(() => {
        if(!session && acctoken && reqtoken){
            signInWithSession(acctoken, reqtoken);
        }
    }, [loadingInitial,session, acctoken, reqtoken])

    async function signInWithSession(_acctoken, _reqtoken) {
        const { data, error } = await  supabase.auth.setSession({ access_token: _acctoken, refresh_token: _reqtoken})
        if(error){
            toast.error(error.message, {position: "bottom-right"});
        } else {

        }
    }
    return (<div className={"flex flex-row h-full w-full justify-center items-center"}>
        <p className={"flex w-auto h-auto text-2xl font-semibold"}>Loading..</p>
    </div>)
}