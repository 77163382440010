import React, {useEffect, useState} from "react";
import {DeleteWidget, LogColor, WinnerMedal} from "./activeLogEntry";
import {Badge, Button, Card, MultiSelect, Paper, ScrollArea} from "@mantine/core";
import {useAtomValue} from "jotai/index";
import {userIdAtom} from "../atoms/appAtoms";

export default function MatchLogEntry(props) {
    const {report, ind, DeleteReport, activeReport, user, activeType} = props;
    const id = useAtomValue(userIdAtom)
    const [logFilter, setLogFilter] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [filteredLogs, setFilteredLogs] = useState([]);


    useEffect(() => {
        if(logFilter.length > 0){
            let lgs = [];
            for(let i = 0; i < report.log.length; i++){
                if(logFilter.includes(report.log[i].type)){
                    lgs.push(report.log[i]);
                }
            }
            setFilteredLogs(lgs);
        } else {
            setFilteredLogs(report.log);
        }
    }, [report ,logFilter])

    return (
        <Card withBorder radius="md"
             className={"flex bg-[#3b6f5e26] rounded-lg gap-1 shadow-md border border-[#51b78c] p-4 mt-4 flex-col h-auto w-full"}>
            <Paper withBorder radius={"sm"} p={"xs"} className="flex flex-col h-auto w-full">
                <div className={"flex flex-row h-10 w-full justify-beteen"}>
                    <Badge w={100} color="blue">{report.matchtype}</Badge>
                    <p className="flex flex-row w-full justify-start items-center text-center text-md pl-4 h-auto text-[#6de9d8] font-semibold">{"Match <" + report.MatchID + ">  " + report.PlayerOne + " vs " +
                        report.PlayerTwo}</p>
                    {!user && expanded && <DeleteWidget  report={report} DeleteReport={DeleteReport} activeReport={activeReport} />}
                </div>
                <div className={"flex flex-row h-10 w-full justify-around"}>
                    <div className="flex flex-row h-auto w-1/2 text-[#6de9d8] text-sm justify-start items-start">{"Player 1: <" +
                        report.pOneID + "> " + report.PlayerOne}{report.winnerID === report.pOneID && <WinnerMedal/>}
                    </div>
                    <div className="flex flex-row h-auto w-1/2 text-[#6de9d8] text-sm justify-start items-start">{"Player 2: <" +
                        report.pTwoID + "> " + report.PlayerTwo}{report.winnerID === report.pTwoID && <WinnerMedal/>}
                    </div>
                </div>
                <div className={"flex flex-row h-10 w-full justify-around"}>
                        <p className="flex flex-row h-auto w-1/2 text-[#6de9d8] text-sm justify-start items-start">{"Winner: " + report.winnerID}</p>
                        <p className="flex flex-row h-auto w-1/2 text-[#6de9d8] text-sm justify-start items-start">{"Total Turns: " + report.totalTurns}</p>
                </div>
                <div className={"flex flex-row h-10 w-full justify-around"}>
                    <p className="flex flex-row h-auto w-1/2 text-[#6de9d8] text-sm justify-start items-start">{"Started on " + report.startTime}</p>
                    <div className={"flex flex-row h-auto w-1/2 justify-end items-center p-2 gap-3"}>
                            <div className="flex flex-row h-auto w-auto justify-center items-center text-[#6de9d8]">Log Filter</div>
                            <div className="flex flex-row h-auto w-[200px] text-[#6de9d8]">
                                <MultiSelect w={"100%"} data={["UnitDamage", "ActionDamage", "Trigger", "Keyword", "Player", "Status", "Deploy", "CardTap", "Selections", "Reset", "Discard", "Drafting"]} value={logFilter} onChange={setLogFilter} />
                            </div>
                    </div>
                </div>
            </Paper>
                <Paper withBorder radius={"sm"} p={"xs"} className={"flex flex-col w-full overflow-y-auto " + (expanded ? " h-[500px]" : " h-[100px]")}>
                        {filteredLogs && filteredLogs.map((el, ind) =>
                            <div key={ind} className={"flex flex-row w-full h-auto text-xs text-white " + LogColor(el.type)}>
                                {el.log}
                            </div>)}
                </Paper>
            <div className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-between items-end">
                <p>{"Ended on " + report.endTime}</p>
                <Button variant={expanded ? "light" : "default"} onClick={() => setExpanded(!expanded)}>{expanded ? "Close" : "Expand"}</Button>
            </div>
        </Card>
    )
}