// src/atoms/newsAtoms.jsx
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { supabase } from '../utils/supabase';

export const feedArticlesAtom = atom([]);
export const loadingNewsAtom = atom(false);
export const editEnabledAtom = atom(false);
export const webhookURLAtom = atom('https://discord.com/api/webhooks/1283535035277770752/y1ovadCbWxrAJvflyxzXtDloTcIHtKinxBXaVeGwoq3hTZrz_cwAwS4PTSNdtgSEHcoo');

// Derived atom for news sync
export const newsSyncAtom = atom(
    async (get) => {
      try {
        const { data, error } = await supabase
            .from('newsfeed')
            .select('*')
            .order('created_at', { ascending: false });
        return data || [];
      } catch (error) {
        console.error('Error fetching news:', error);
        throw error;
      }
    }
);