import {atom} from 'jotai';
import {activeSortAtom, reportsAtom} from "./adminAtoms";
import {supabase} from "../utils/supabase";
let cs = localStorage.getItem("cardsize");
let cp = localStorage.getItem("cardspage");

export const isSavingAtom = atom(false);
export const saveQueueAtom = atom(false);
export const activeDeckAtom = atom(null)
export const activeDeck2Atom = atom(null);
export const myDecksAtom = atom([]);
export const decksAtom = atom([]);
export const unitsAtom = atom([]);
export const actionsAtom = atom([]);
export const resourcesAtom = atom([]);
export const cardSizeAtom = atom(cs !== null ? parseFloat(cs) : 0.28);
export const cardsPerPageAtom = atom(cp !== null ? parseInt(cp) : 24);
export const keywordsAtom = atom([]);
export const elementsAtom = atom([]);
export const identitiesAtom = atom([]);
export const previewDeckAtom = atom({});
export const previewingAtom = atom(false);
export const adAtom = atom(localStorage.getItem("activedeck") ? parseInt(localStorage.getItem("activedeck")): -1);
export const aD2Atom = atom(0);
export const starterScreenAtom = atom(false);
export const deckWindowAtom = atom(false);
export const cardbacksAtom = atom([]);
export const searchTextAtom = atom("");
export const currentPageAtom = atom(1);
export const IdentityFilterAtom = atom([]);
export const identityAtom = atom("And");
export const keywordFilterAtom = atom([]);
export const keywordNotAtom = atom(false);
export const keywordAtom = atom("And");
export const DisplayModeAtom = atom("All");
export const sortTypeAtom = atom("CostAsc");
export const editionAtom = atom("Base Set");
export const deckfilterActiveAtom = atom(false);
export const InDeckAtom = atom("all");
export const editionsAtom = atom([]);
export const decksLoadingAtom = atom(true);
export const showOwnAtom = atom(false);
export const hideAddedAtom = atom(true);
export const hideIncompleteAtom = atom(true);
export const deckstAtom = atom("");
export const decksPerPageAtom = atom(10);
export const currentDeckPageAtom = atom(0);
export const deckPagesAtom = atom([]);
export const cardsLoadingAtom = atom(false);
export const allCardsAtom = atom([]);
export const cardTooltipsAtom = atom(false);
export const identityNotAtom = atom(false);
export const displayDeckAtom = atom(null);
export const loadingDataAtom = atom(false);
export const visibleCardsAtom = atom(0);
