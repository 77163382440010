import {atom} from 'jotai';

export const openPageAtom = atom(null);
export const sessionAtom = atom(null);
export const userIdAtom = atom('');
export const profileAtom = atom(null);
export const permissionsAtom = atom([]);
export const cardsOnlyAtom = atom(false);
export const matchIdAtom = atom('');
export const hideMenuAtom = atom(false);
export const isAdminAtom = atom(false);
export const isInitialLoadAtom = atom(true);
export const loadingInitialAtom = atom(true);
export const discordAtom = atom(null);

