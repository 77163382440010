import {Button, Card, Paper, ScrollArea, Tabs} from "@mantine/core";
import {useAtomValue} from "jotai/index";
import {profileAtom} from "../atoms/appAtoms";
import {PriceCheckOutlined} from "@mui/icons-material";
import {IconArticle} from "@tabler/icons-react";
import {useEffect, useState} from "react";

const col = "#0d1b15"

export default function TreasuryModal(props){
    const profile = useAtomValue(profileAtom);
    const [openTab, setOpentab] = useState("purchase")
    const [goldLog, setGoldLog] = useState([]);

    useEffect(() => {
        if(profile){
            setGoldLog(profile.goldlog.reverse());
        } else {
            setGoldLog([])
        }
    }, [profile])


    return (
        <div className={"absolute z-50 flex flex-col w-full lg:w-[400px] h-[80%] lg:h-[400px] border-l border-b border-[#51b78c] border-opacity-70 bg-[#193025FF] rounded-bl-2xl right-0 top-20 overflow-hidden"}>
            <div className={"flex flex-row h-10 w-full justify-center gap-3 items-center bg-[#0D1B15FF] py-2"}>
                <Button size={"xs"} variant={openTab === "purchase" ? "light" : "default"} onClick={() => setOpentab("purchase")} >Purchase</Button>
                <Button size={"xs"}  variant={openTab === "log" ? "light" : "default"} onClick={() => setOpentab("log")} >History</Button>
            </div>
            {openTab === "purchase" && <div className={"flex flex-col w-full h-full bg-[#193025FF]"}>
                <ScrollArea className={"flex flex-col w-full h-full p-4 pr-6"}>
                    <Card withBorder radius={"md"} p={"sm"} w={"auto"} h={100} className={"mb-2"}>
                        Option 1
                    </Card>
                    <Card withBorder radius={"md"} p={"sm"} w={"auto"} h={100} className={"mb-2"}>
                        Option 2
                    </Card>
                    <Card withBorder radius={"md"} p={"sm"} w={"auto"} h={100} className={"mb-2"}>
                        Option 3
                    </Card>
                    <Card withBorder radius={"md"} p={"sm"} w={"auto"} h={100}>
                        Option 4
                    </Card>
                </ScrollArea>
            </div>}
            {openTab === "log" && <div className={"flex flex-col w-full h-full pl-1 pt-1 pb-1 overflow-hidden bg-[#193025FF]"}>
                <ScrollArea className={"flex flex-col w-full h-full pr-2"}>
                    {goldLog.map((log) => (
                        <Paper withBorder radius={"md"} p={"xs"} className={"flex flex-col w-full h-14 tex-xs mb-1"} key={log.date}>
                            <div className={"flex flex-row w-full h-1/2"}>
                                <p className={"flex flex-row w-1/2 h-10 text-xs"}>{log.date}</p>
                                <p className={"flex flex-row w-1/2 h-10 text-xs"}>{log.amount}</p>
                            </div>
                            <div className={"flex flex-row w-full h-1/2"}>
                                <p className={"flex flex-row w-full h-10 text-xs"}>{log.reason}</p>
                            </div>
                        </Paper>
                    ))}
                </ScrollArea>
            </div>}
        </div>
    )
}