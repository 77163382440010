import {CircularProgress} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, {Suspense, useEffect, useState} from "react";
import { copy } from "@stianlarsen/copy-to-clipboard";
import toast from "react-hot-toast";
import {supabase} from "../utils/supabase";
import { ReactTabulator } from 'react-tabulator'
import usePagination from "@mui/material/usePagination";
import {cardlist} from "../utils/cardlist";
import {useAtom} from "jotai/index";
import {
    actionsAtom, cardbacksAtom,
    cardsLoadingAtom, editionsAtom,
    elementsAtom, identitiesAtom,
    keywordsAtom,
    resourcesAtom,
    unitsAtom
} from "../atoms/deckBuilderAtoms";

const draftlist = [
    "Accelerated Decay",
    "Ancestral Anthem",
    "Ancestral Knowledge",
    "Ancestral Oath",
    "Anchorsteel Bannerman",
    "Anchorsteel Captain",
    "Anchorsteel Dash",
    "Anchorsteel Devotion",
    "Anchorsteel Gatewarden",
    "Anchorsteel Guidance",
    "Anchorsteel Initiation",
    "Anchorsteel Insight",
    "Anchorsteel Siegebreaker",
    "Anchorsteel Warcaller",
    "Ancient Spirit Capsule",
    "Angelic Advice",
    "Archmage Training",
    "Armored Assault",
    "Bad Intentions",
    "Bayou Hobgoblin",
    "Big Trouble",
    "Bladestorm Battlemage",
    "Bladestorm Squire",
    "Bloodstorm",
    "Boiling Bannerman",
    "Boost Morale",
    "Brace for Impact",
    "Bramblevine Crusader",
    "Bramblevine Headhunter",
    "Bramblevine Ogre",
    "Bramblevine Shadowcaster",
    "Break the Stall",
    "Brightleaf Battlemage",
    "Brightleaf Brigade",
    "Brightleaf Captain",
    "Brightleaf Phalanx",
    "Brightleaf Porter",
    "Brightleaf Shadowstalker",
    "Brightleaf Willow",
    "Brightsky Headhunter",
    "Brilliant Battlemage",
    "Brilliant Captain",
    "Brilliant Hobgoblin",
    "Broadside Barrage",
    "Chronic Pain",
    "Cipher Break",
    "Cloudbreaker Titan",
    "Cold-Blooded Killer",
    "Compounding Lethality",
    "Condemned to Chains",
    "Contaminated Ejecta",
    "Corrupted Soul",
    "Cost of Knowledge",
    "Dangerous Information",
    "Dark Bargain",
    "Darkwood Porter",
    "Dawnrise Sergeant",
    "Dawnwood Dash",
    "Debilitating Disease",
    "Decimation Shot",
    "Deep Study",
    "Deepforge Porter",
    "Deepwater Gargantuan",
    "Demon Blood Infusion",
    "Demonic Dash",
    "Demonic Direction",
    "Demonic Durability",
    "Dessicated Ogre",
    "Divine Glory",
    "Dockmist Harborguard",
    "Dockmist Warlock",
    "Dockside Arsonist",
    "Drown in Darkness",
    "Echostrike Headhunter",
    "Echostrike Mercenary",
    "Echostrike Sergeant",
    "Echostrike Shadowcaster",
    "Echostrike Siegebreaker",
    "Echostrike Vindicator",
    "Electrostatic Capacitor",
    "Electrostatic Education",
    "Emberguard Bannerman",
    "Emberguard Blessing",
    "Emberguard Captain",
    "Emberguard Dash",
    "Emberguard Infantry",
    "Emberguard Insight",
    "Emberguard Inspiration",
    "Emberguard Mercenary",
    "Emberguard Siegebreaker",
    "Emberguard Siege-Captain",
    "Emberguard Spellweaver",
    "Emberguard Squire",
    "Emberguard Warcaller",
    "Emberstrike Titan",
    "Exhaustive Study",
    "Famine of Hope",
    "Feed the Beast",
    "Fel Jab",
    "Felfire Airburst",
    "Felfire Torrent",
    "Felfire Visions",
    "Felstone Bolide",
    "Felstone Fissures",
    "Firestorm Footman",
    "Firestorm Warlock",
    "Flying Knee",
    "Focused Research",
    "Friendly Advice",
    "Friendly Insight",
    "Frostfire Blessing",
    "Frostfire Inspiration",
    "Furious Blast",
    "Glacierfrost Overseer",
    "Glacierfrost Vindicator",
    "Glacierthorn Strikeforce",
    "Goldenbough Mercenary",
    "Good Tidings",
    "Harbor Headhunter",
    "Hidden Hermit",
    "Hinterland Warleader",
    "Hobgoblin Assassin",
    "Hobgoblin Botanist",
    "Hobgoblin Duelist",
    "Hobgoblin Hauler",
    "Hobgoblin Heistmeister",
    "Hobgoblin Shaman",
    "Hobgoblin Spearhead",
    "Hobgoblin Stevedore",
    "Hungry Fel-Fissure",
    "Hydroforge Technician",
    "Incremental Advantage",
    "Insidious Affliction",
    "Inspired Assault",
    "Instill Courage",
    "Invaluable Experience",
    "Ironheart Linebreaker",
    "Jab",
    "Lamplight Guidance",
    "Lamplight Paladin",
    "Lamplight Porter",
    "Lasting Lesson",
    "Lavacore Brigand",
    "Lavacore Captain",
    "Lavacore Cavalry",
    "Lavacore Porter",
    "Lavacore Sappers",
    "Lavacore Twinblade",
    "Lavacore Twincaster",
    "Lavaspark Hobgoblin",
    "Lavaspark Porter",
    "Lavaspark Warlock",
    "Local Catastrophe",
    "Loyal Gatewarden",
    "Magmaforge Blessing",
    "Magmasteam Captain",
    "Magmasteam Dash",
    "Magmasteam Devotion",
    "Magmasteam Guidance",
    "Magmasteam Hobgoblin",
    "Magmasteam Infantry",
    "Magmasteam Initiation",
    "Magmasteam Porter",
    "Magmasteam Retaliator",
    "Magmasteam Siegebreaker",
    "Magmasteam Siege-Captain",
    "Magmasteam Warcaller",
    "Mangrove Porter",
    "Mass Misery",
    "Mesa Sandstalker",
    "Midnight Battlemage",
    "Midnight Crusader",
    "Mission Recon",
    "Mistwood Captain",
    "Mistwood Mercenary",
    "Mistwood Warlock",
    "Moongrace Blessing",
    "Moongrace Dash",
    "Moongrace Guidance",
    "Moongrace Mercenary",
    "Moongrace Siegebreaker",
    "Moongrace Siege-Captain",
    "Moongrace Warcaller",
    "Nightfall Titan",
    "Nightfire Devotion",
    "Nightfire Initiation",
    "Nightshade Hobgoblin",
    "Nightshade Insight",
    "Nightshade Sentinel",
    "Nightshade Shaman",
    "Nightshade Strategist",
    "Nightshade Vassal",
    "Nightshade Watchman",
    "Nocturnal Navigator",
    "Officer Training",
    "Ongoing Issue",
    "Optical Implants",
    "Overclocked Battlemage",
    "Overclocked Headhunter",
    "Overclocked Titan",
    "Painful Progress",
    "Penumbral Porter",
    "Perpetual Misery",
    "Persistent Injury",
    "Pit-Lord","s Hunger",
    "Platinum Porter",
    "Prairie Patrol",
    "Prairie Porter",
    "Proactive Fortification",
    "Radiant Insight",
    "Rapid Reflexes",
    "Razorleaf Assassin",
    "Scalding Insight",
    "Seaspray Porter",
    "Seaspray Slayer",
    "Shadowblade Captain",
    "Shadowblade Headhunter",
    "Shadowblade Nightwatch",
    "Shadowblade Ogre",
    "Shadowblade Racketeer",
    "Shadowblade Reaver",
    "Shadowblade Tracker",
    "Shadowsilk Devotion",
    "Shadowsilk Initiation",
    "Shadowsilk Inspiration",
    "Shadowsilk Ranger",
    "Shimmering Shadowcaster",
    "Shimmering Soldier",
    "Shimmering Stalker",
    "Shimmering Swordsman",
    "Shock Trooper",
    "Shocksteel Captain",
    "Shocksteel Dash",
    "Shocksteel Devotion",
    "Shocksteel Guidance",
    "Shocksteel Hobgoblin",
    "Shocksteel Initiation",
    "Shocksteel Siege-Captain",
    "Shocksteel Spellweaver",
    "Shocksteel Warcaller",
    "Siege Preparations",
    "Slam",
    "Smoldering Gatewarden",
    "Smoldering Ogre",
    "Soul Harvest",
    "Soulbound Sacrifice",
    "Soulfire Slam",
    "Soul-Stealing Strike",
    "Starforge Squire",
    "Starforge Warlock",
    "Starshadow Dash",
    "Starshadow Devotion",
    "Starshadow Footman",
    "Starshadow Guidance",
    "Starshadow Initiation",
    "Starshadow Inspiration",
    "Starshadow Mercenary",
    "Starshadow Vassal",
    "Steamforge Spellweaver",
    "Steelforge Battlemage",
    "Steelforge Captain",
    "Steelforge Ogre",
    "Steelforge Porter",
    "Steelforge Sergeant",
    "Steelforge Squadron",
    "Steelforge Squire",
    "Steelforge Stalker",
    "Steelforge Swordsman",
    "Steelforge Warlord",
    "Strategy Session",
    "Sudden Breakthrough",
    "Sunburst Skirmisher",
    "Sungrace Blessing",
    "Sungrace Captain",
    "Sungrace Devotion",
    "Sungrace Druid",
    "Sungrace Guidance",
    "Sungrace Initiation",
    "Sungrace Inspiration",
    "Sungrace Porter",
    "Sungrace Siegebreaker",
    "Sungrace Siege-Captain",
    "Sungrace Soldier",
    "Sungrace Squire",
    "Sungrace Vassal",
    "Sungrace Warcaller",
    "Sungrace Warden",
    "Sunsteel Bannerman",
    "Sunsteel Blessing",
    "Sunsteel Dash",
    "Sunsteel Devotion",
    "Sunsteel Guidance",
    "Sunsteel Initiation",
    "Sunsteel Insight",
    "Sunsteel Inspiration",
    "Sunsteel Sergeant",
    "Sunsteel Siegebreaker",
    "Sunsteel Siege-Captain",
    "Sunsteel Spellshaper",
    "Sunsteel Squadron",
    "Sunsteel Strider",
    "Sunsteel Warcaller",
    "Sunstrike Sergeant",
    "Sunstrike Titan",
    "Tactical Foresight",
    "Tactical Supremacy",
    "Thorough Training",
    "Thrown to the Pit",
    "Tidegrace Blessing",
    "Tidegrace Dash",
    "Tidegrace Devotion",
    "Tidegrace Guidance",
    "Tidegrace Hierophant",
    "Tidegrace Initiation",
    "Tidegrace Insight",
    "Tidegrace Inspiration",
    "Tidegrace Siegebreaker",
    "Tidegrace Siege-Captain",
    "Tidegrace Trooper",
    "Tidegrace Vassal",
    "Tidegrace Warcaller",
    "Tidehammer Battlemage",
    "Tidehammer Blessing",
    "Tidehammer Inspiration",
    "Tidehammer Sergeant",
    "Tidehammer Shieldbreaker",
    "Tidehammer Siege-Captain",
    "Tidehammer Squire",
    "Tidehammer Titan",
    "Tidehammer Trapper",
    "Tip the Balance",
    "Titanic Cannonade",
    "Titanic Exsanguination",
    "Twilight Blessing",
    "Twilight Hobgoblin",
    "Twilight Insight",
    "Twilight Ranger",
    "Twilight Sergeant",
    "Twilight Siegebreaker",
    "Twilight Siege-Captain",
    "Twilight Spellweaver",
    "Twilight Troopers",
    "Twilight Warcaller",
    "Twilight Warlock",
    "Undercity Botanist",
    "Undercity Hobgoblin",
    "Undercity Larcenist",
    "Undercity Porter",
    "Uppercut",
    "Vindictive Hobgoblin",
    "Volcanic Inspiration",
    "Volcanic Vassal",
    "Worst Wishes",
    "Wretched Fulmination"
];

const rcardoverrides = [
    {
        "_id": "64bc01296557f9fcad067b1a",
        "Name": "Glimmerbough Shard"
    },
    {
        "_id": "64bc01296557f9fcad067b19",
        "Name": "Overgrown Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b16",
        "Name": "Lavacore Gem"
    },
    {
        "_id": "64bc01296557f9fcad067b27",
        "Name": "Emberleaf Tangle"
    },
    {
        "_id": "64bc01296557f9fcad067b2a",
        "Name": "Shocksteel Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b21",
        "Name": "Steamblaze Shore"
    },
    {
        "_id": "64bc01296557f9fcad067b28",
        "Name": "Moonglow Geode"
    },
    {
        "_id": "64bc01296557f9fcad067b25",
        "Name": "Darkwood Tangle"
    },
    {
        "_id": "64bc01296557f9fcad067b22",
        "Name": "Nightfire Badlands"
    },
    {
        "_id": "64bc01296557f9fcad067b18",
        "Name": "Shadowsteel Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b20",
        "Name": "Shadowmist Estuary"
    },
    {
        "_id": "64bc01296557f9fcad067b24",
        "Name": "Enduring Ingot"
    },
    {
        "_id": "64bc01296557f9fcad067b23",
        "Name": "Sunstone Crystal"
    },
    {
        "_id": "64bc01296557f9fcad067b1d",
        "Name": "Sungrace Expanse"
    },
    {
        "_id": "64bc01296557f9fcad067b1f",
        "Name": "Scorching Mesa"
    },
    {
        "_id": "64bc01296557f9fcad067b1e",
        "Name": "Penumbral Prairie"
    },
    {
        "_id": "64bc01296557f9fcad067b17",
        "Name": "Sunsteel Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b1c",
        "Name": "Sunspray Strand"
    },
    {
        "_id": "64bc01296557f9fcad067b29",
        "Name": "Eternal Ice"
    },
    {
        "_id": "64bc01296557f9fcad067b1b",
        "Name": "Mistwood Coast"
    },
    {
        "_id": "64bc01296557f9fcad067b26",
        "Name": "Anchorsteel Quarry"
    }
]

export default function CardUpdate(props) {
    const [updatingCards, setUpdatingCards] = useState(false);
    const [key, setKey] = useState(0);
    const [dataSet, setDataSet] = useState(2);
    const [units, setUnits] = useAtom(unitsAtom);
    const [actions, setActions] = useAtom(actionsAtom);
    const [resources, setResources] = useAtom(resourcesAtom);
    const [cardsLoaded, setCardsLoaded] = useState(false);
    const [cards, setCards] = useState([]);
    const [keywords, setKeywords] = useAtom(keywordsAtom);
    const [elements, setElements] = useAtom(elementsAtom);
    const [identities, setIdentities] = useAtom(identitiesAtom);
    const [cardbacks, setCardbacks] = useAtom(cardbacksAtom);
    const [editions, setEditions] = useAtom(editionsAtom);

    const jsonMutator = function(value, data, type, params, component){
        return JSON.stringify(value); //return the new value for the cell data.
    }

    async function getcards() {
        const { data, error } = await supabase
            .from('carddata')
            .select()
            .eq("id", 2)
        if(error){
            console.log(error);
            setCardsLoaded(true);
        } else {
            setUnits(data[0].units);
            setActions(data[0].actions);
            setResources(data[0].resources);
            setKeywords(data[0].keywords);
            setElements(data[0].elements);
            setIdentities(data[0].identities);
            setCardbacks(data[0].cardBacks);
            let ls = [];
            data[0].units.forEach((el)=> { if(!ls.includes(el.edition)) ls.push(el.edition); });
            data[0].actions.forEach((el)=> { if(!ls.includes(el.edition)) ls.push(el.edition); });
            setEditions(ls);
            setCardsLoaded(true);
        }
    }

    function ReturnIDList(){
        let ls = [];
        for(let i = 0; i < units.length; i++){
            if(draftlist.includes(units[i].name)) ls.push(units[i]._id);
        }
        for(let i = 0; i < actions.length; i++){
            if(draftlist.includes(actions[i].name)) ls.push(actions[i]._id);
        }
        copy(JSON.stringify(ls), ClipbackCB);
    }

    const columns = [
        {title: "Type", field: "type"},
        {title: "ID", field: "_id"},
        {title: "CardName", field: "name", editor:"input"},
        {title: "Identity", field: "identity", editor:"list", editorParams:{ placeholderLoading:"Loading List...", emptyValue:null,listOnEmpty:true,verticalNavigation:"hybrid", sort:"asc", //sort direction for the values list
                defaultValue:"Neutral",  autocomplete:true,
                values: [
                    "Earth",
                    "Flame",
                    "Lunar",
                    "Metal",
                    "Solar",
                    "Water",
                    "Neutral",
                    "Flame-Metal",
                    "Flame-Water",
                    "Lunar-Earth",
                    "Lunar-Flame",
                    "Solar-Earth",
                    "Solar-Lunar",
                    "Solar-Metal",
                    "Water-Earth",
                    "Water-Metal"
                ]}},
        {title: "Text", field: "ability", hozAlign: "center", editor:"input"},
        {title: "Keywords", field: "keywords", hozAlign: "center", editor:"input", width:300},
        {title: "Costs", field: "costs", hozAlign: "center",mutator: jsonMutator, editor:"textarea", width:300},
        {title: "Triggers", field: "triggers", hozAlign: "center",mutator: jsonMutator, editor:"textarea", width:300}
    ];

    async function UpdateCards(cindex) {
        setUpdatingCards(true);
        let units = [];
        let actions = [];
        let resources = [];
        await fetch("https://card-calc-db.onrender.com/export-json").then(response => response.json().then(data => {
            if(data){
                for (let c = 0; c < data.length; c++) {
                    let card = data[c];
                    if (typeof card.inFirstEdition !== "boolean") card.inFirstEdition = false;
                    let triggers = card.triggers;
                    if (triggers.length > 0) {
                        for (let i = 0; i < triggers.length; i++) {
                            let trigg = triggers[i];
                            if (typeof trigg.name !== "string") trigg.name = trigg.name.toString();
                            if (typeof trigg.condition !== "string") trigg.condition = trigg.condition.toString();
                            if (typeof trigg.targetType !== "string") trigg.targetType = trigg.targetType.toString();
                            if (typeof trigg.targetQty !== "number") trigg.targetQty = isNaN(parseInt(trigg.targetQty)) ? 0 : parseInt(trigg.targetQty);
                            if (typeof trigg.execute !== "string") trigg.execute = trigg.execute.toString();
                            if (typeof trigg.orFlag === "string") trigg.orFlag = parseInt(trigg.orFlag);
                            if (typeof trigg.orFlag !== "number") trigg.orFlag = 0;
                            if (!Array.isArray(trigg.effect)) trigg.effect = [];
                            else {
                                for (let i2 = 0; i2 < trigg.effect.length; i2++) {
                                    let eff = trigg.effect[i2];
                                    if (typeof eff.valueA !== "number") eff.valueA = isNaN(parseInt(eff.valueA)) ? 0 : parseInt(eff.valueA);
                                    if (typeof eff.valueB !== "number") eff.valueB = isNaN(parseInt(eff.valueB)) ? 0 : parseInt(eff.valueB);
                                    if (typeof eff.duration !== "string") eff.duration = eff.duration.toString();
                                    if (!Array.isArray(eff.valueC)) {
                                        let vc = [];
                                        vc.push(eff.valueC.toString());
                                        eff.valueC = vc;
                                    } else {
                                        for (let vals = 0; vals < eff.valueC.length; vals++) {
                                            let val = eff.valueC[vals];
                                            if (typeof val !== "string") eff.valueC[vals] = val.toString();
                                        }
                                    }
                                    trigg.effect[i2] = eff;
                                }
                            }
                            card.triggers[i] = trigg;
                        }
                    }

                    if (card.type.toLowerCase() === "action") {
                        const acard = {
                            _id: card.id,
                            name: card.name,
                            abilitydescription: card.ability,
                            ability: card.ability,
                            identity: card.identity,
                            costs: card.costs,
                            triggers: card.triggers,
                            keywords: card.keywords,
                            edition: card.edition,
                            inFirstEdition: card.inFirstEdition,
                            specialEdition: card.specialEdition ? card.specialEdition : "",
                            skillCategoryName: card.skillCategoryName ? card.skillCategoryName : "",
                            versions: [{
                                Name: "default",
                                version: 0
                            }]
                        }
                        actions.push(acard);
                    } else if (card.type.toLowerCase() === "unit") {
                        const ucard = {
                            _id: card.id,
                            name: card.name,
                            abilitydescription: card.ability,
                            ability: card.ability,
                            identity: card.identity,
                            costs: card.costs,
                            triggers: card.triggers,
                            atk: card.atk,
                            edition: card.edition,
                            def: card.def,
                            keywords: card.keywords,
                            inFirstEdition: card.inFirstEdition,
                            specialEdition: card.specialEdition ? card.specialEdition : "",
                            skillCategoryName: card.skillCategoryName ? card.skillCategoryName : "",
                            versions: [{
                                "Name": "default",
                                "version": 0
                            }]
                        }
                        units.push(ucard);
                    } else if(card.type.toLowerCase() === "mana"){
                        const rcard = {
                            _id: card.id,
                            Name: card.name,
                            identity: card.identity,
                            versions: [
                                {
                                    Name: "default",
                                    version: 0
                                }
                            ]
                        }
                        for(let i = 0; i < rcardoverrides.length; i++){
                            if(rcardoverrides[i].Name === card.name) {
                                rcard._id = rcardoverrides[i]._id;
                                break;
                            }
                        }
                        resources.push(rcard);
                    }
                }
                let cdata = { units: units, actions: actions, resources: resources };
                supabase.from('carddata')
                    .update(cdata)
                    .eq('id', cindex)
                    .select().then(result => {
                    if(result.error){
                        toast.error("Failed to update cards: " + result.error, {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                        setUpdatingCards(false);
                    } else {
                        console.log(JSON.stringify(units) + "\n" + JSON.stringify(actions) + "\n" + JSON.stringify(resources));
                        toast.success("updated " + units.length + " unit cards, " + actions.length + " action cards and " + resources.length + " mana cards, total " + (units.length + actions.length), {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                        setUpdatingCards(false);
                    }
                })
            } else {
                toast.error("Failed to update cards.", {
                    duration: 4000,
                    position: 'bottom-right',
                });
                setUpdatingCards(false);
            }
        }));
    }

    const ClipbackCB = () => {
        toast.success("Password has been copied to the clipboard", {
            duration: 4000,
            position: 'bottom-right',
        });
    };

    useEffect(() => {
        let crds = [];
        if(units && units.length > 0) {
            for (let i = 0; i < units.length; i++) {
                units[i].type = "Unit";
                crds.push(units[i]);
            }
        }
        if(actions && actions.length > 0) {
            for (let i = 0; i < actions.length; i++) {
                actions[i].type = "Action";
                crds.push(actions[i]);
            }
        }
        setCards(crds);
    }, [actions, units])

    useEffect(() => {

        if(key === 10){
            setKey(0);
        } else setKey(key + 1);
    }, [updatingCards]);

    const options = {
        height: "100%",
        movableRows: false,
        movableColumns: true,
        pagination: true,
        layout: "fitData",
        contentEditable: true,
        width: "100%",
    };

    return (
        <div className={"flex flex-col w-full h-full overflow-hidden"}>
            <Suspense fallback={<div>Loading card data...</div>}>
            <div className={"flex flex-col h-full w-full overflow-hidden"}>
                {!updatingCards && cards &&
                    <ReactTabulator
                    data={cards}
                    options={options}
                    columns={columns}/>
                }
                {updatingCards && <div className={"flex flex-col justify-center items-center h-full w-full"}>
                    <CircularProgress/>
                    <p> Updating Cards..</p>
                </div>}
            </div>
            </Suspense>
            <div
                className={"flex flex-row w-full h-14 border-t border-[#51b78c] justify-between items-center px-10 gap-2"}>
                <div>
                    <div className={"text-green-400 hover:opacity-70"} onClick={() => {
                        ReturnIDList();
                    }}>Get Draft List
                    </div>
                </div>


                {updatingCards ?
                    <div
                        className={"bg-[#3b6f5e26] opacity-80 w-auto px-2 flex flex-row h-10 border border-[#51b78c] rounded-xl items-center justify-center"}
                    >
                        <p> Updating Cards..</p>
                    </div>
                    :
                    <div className={"flex flex-row w-auto h-full justify-center items-center p-2 gap-2"}>
                        <div className={"flex flex-row w-auto h-full justify-center items-center gap-2"}>
                            Dataset:
                            <input value={dataSet} onChange={(e) => { setDataSet(parseInt(e.currentTarget.value))}} datatype={"number"} className={"flex flex-row w-[50px] h-full text-center bg-[#244939] border border-[#51b78c] p-1"} />
                        </div>
                        <Tooltip title="Update Cards" placement="right">
                            <div
                                className={"bg-[#3b6f5e26] w-auto px-2 flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                                onClick={() => {
                                    UpdateCards(dataSet);
                                }}
                            >
                                <RefreshIcon size={"small"} />
                                <p> Update Cards</p>
                            </div>
                        </Tooltip>
                    </div>
                }
            </div>
        </div>
    )
}

function CardsHeader(props) {
    return (
        <thead>
        <tr>
            <th>Type</th>
            <th>ID</th>
            <th>CardName</th>
            <th>Identity</th>
            <th>Costs</th>
            <th>Text</th>
            <th>Triggers</th>
            <th>Keywords</th>
        </tr>
        </thead>
    )
}

const playerentrystyle = "flex-row text-center justify-center items-center border-r border-[#51b78c] border-opacity-40";

function CardItem(props) {
    const {entry, type} = props;

    return (
        <tr className={"border border-[#51b78c] border-opacity-40"}>
            <td className={playerentrystyle}>{type}</td>
            <td className={playerentrystyle}>{entry._id}</td>
            <th className={playerentrystyle}>{entry.name}</th>
            <th className={playerentrystyle}>{entry.identity}</th>
            <td className={playerentrystyle}>{JSON.stringify(entry.costs)}</td>
            <td className={playerentrystyle}>{entry.ability}</td>
            <td className={playerentrystyle}>{JSON.stringify(entry.triggers)}</td>
            <td className={playerentrystyle}>{JSON.stringify(entry.keywords)}</td>
            <td className={playerentrystyle}>
            </td>
        </tr>
    )
}