import {useAtom} from 'jotai';
import {supabase} from '../utils/supabase';
import { useEffect, useCallback } from 'react';
import { gethDateTime } from '../utils/supabase';
import {
  sessionAtom,
  userIdAtom,
  profileAtom,
  isAdminAtom,
  loadingInitialAtom,
  isInitialLoadAtom,
  discordAtom,
  hideMenuAtom
} from '../atoms/appAtoms';
import {useLocation} from "react-router-dom";

export const useApp = () => {
  const [session, setSession] = useAtom(sessionAtom);
  const [id, setId] = useAtom(userIdAtom);
  const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
  const [loadingInitial, setLoadingInitial] = useAtom(loadingInitialAtom);
  const [isInitialLoad, setIsInitialLoad] = useAtom(isInitialLoadAtom);
  const [hideMenu, setHideMenu] = useAtom(hideMenuAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [profile, setProfile] = useAtom(profileAtom);

  let route = useLocation();

  const checkDiscord = useCallback(async () => {
    const { data, error } = await supabase.auth.getUserIdentities();
    if (error) {
      setDiscord(null);
    } else {
      const discordIdentity = data.identities.find(
        (identity) => identity.provider === 'discord'
      );
      setDiscord(discordIdentity || null);
    }
  }, [session]);

  const createProfile = async (_id, _username, _email) => {
    if (_id === '') {
      setId('');
      setProfile(null);
      setIsAdmin(false);
      if (loadingInitial) setLoadingInitial(false);
      return;
    }

    const { error } = await supabase.from('profiles').insert({
      username: _username,
      steamid: 0,
      gold: 150,
      lastlogin: gethDateTime(),
      banned: false,
      level: 1,
      goldlog: [{ amount: 150, reason: 'Registration', date: gethDateTime() }],
      lastdaily: '1',
      online: false
    });

    if (error) {
      setId('');
      setProfile(null);
      setIsAdmin(false);
      if (loadingInitial) setLoadingInitial(false);
    } else {
      await getProfile(_id, _username, _email);
    }
  };

  const getProfile = async (_id, _username, _email) => {
    if (_id === '') {
      setId('');
      setProfile(null);
      setIsAdmin(false);
      if (loadingInitial) setLoadingInitial(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('profiles')
        .select()
        .eq('user_id', _id);
      if (data.length > 0) {
        setProfile(data[0]);
        setId(_id);
        const { data: isAdminData, error: isAdminError } = await supabase.rpc('is_admin');
        setIsAdmin(isAdminError ? false : isAdminData);
        await checkDiscord();
        if (loadingInitial) setLoadingInitial(false);
      } else {
        await createProfile(_id, _username, _email);
      }
    } catch (error) {
      console.error('Error getting profile:', error);
      setId('');
      setProfile(null);
      setIsAdmin(false);
      if (loadingInitial) setLoadingInitial(false);
    }
  };

  const openPage = () => {
    if (route.pathname === "/") {
      return "/";
    } else {
      return route.pathname.split("/")[1];
    }
  }

  return {
    openPage,
    session,
    id,
    profile,
    isAdmin,
    loadingInitial,
    discord,
    getProfile,
    checkDiscord,
    hideMenu,
    setHideMenu
  };
};