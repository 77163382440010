import React, {useState} from "react";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useAtom, useAtomValue} from "jotai/index";
import {profileAtom, sessionAtom} from "../atoms/appAtoms";

export default function FeedPostModal(props) {
    const {setPosterOpen} = props;
    const session = useAtomValue(sessionAtom);
    const profile = useAtomValue(profileAtom);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");


    async function PostFeed() {
        let content = message;
        if(type === "Patch Notes"){
            let nm = {"rdate":"","description":"","features":[],"fixes":[],"issues":[], "improvements": []};
            content = JSON.stringify(nm);
        }

        const {error} = await supabase.from('newsfeed').insert({
            type: type,
            short: title,
            poster: profile.username,
            title: title,
            content: content,
            edited_at: Date.now().toString()
        });
        
        if(error){
            toast.error('Failed to post article.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        
        toast.success('Posted news article successfully.', {
            duration: 4000,
            position: 'bottom-right',
        });
        setPosterOpen(false);
    }

    return (
        <div className={"flex flex-col w-full h-screen p-4"}>
            <div className="w-full px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-base-200">
                    <div className="flex-auto p-5 lg:p-10">
                        <label className="form-control w-full max-w-xs" htmlFor="type">
                            <select className="select select-bordered bg-[#244939] text-white border border-[#51b78c]"
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.currentTarget.value);
                                    }}
                                    style={{transition: "all .15s ease"}}
                            >
                                <option disabled selected>Select article type</option>
                                <option>Announcement</option>
                                <option>Bug Fixes</option>
                                <option>Patch Notes</option>
                            </select>
                        </label>
                        <div className="relative w-full mb-3 mt-8">
                            <label
                                className="block uppercase text-white text-xs font-bold mb-2"
                                htmlFor="title"
                            >
                                Title
                            </label>
                            <input
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.currentTarget.value);
                                }}
                                type="text"
                                className="bg-[#244939] border-[#51b78c] border-0 px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Article Title"
                                style={{transition: "all .15s ease"}}
                            />
                        </div>
                        <div className="relative w-full mb-3 mt-8">
                            <label
                                className="block uppercase text-white text-xs font-bold mb-2"
                                htmlFor="content"
                            >
                                Content
                            </label>
                            <textarea id={"content"}
                                className={"min-h-[200px] h-full w-full bg-[#244939] border border-[#51b78c] text-white"}
                                value={message} onChange={(e) => {
                                setMessage(e.currentTarget.value);
                            }}>

                            </textarea>
                        </div>
                        <div className="text-center mt-6">
                            <button
                                className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={() => {
                                        setPosterOpen(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={() => {
                                        PostFeed();
                                    }}
                                >
                                    Post
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
            }