import React, {useEffect, useState} from "react";
import {BarChart, PieChart} from '@mantine/charts';
import {useAtomValue} from "jotai/index";
import {cardStatsAtom, lossesAtom, totalPlayedAtom, winsAtom} from "../atoms/statisticsAtoms";
import {Anchor, Card, Group, List, Paper, SimpleGrid, Text} from "@mantine/core";

export default function StatsDash(props) {
    const cardStats = useAtomValue(cardStatsAtom);
    const wins = useAtomValue(winsAtom);
    const losses = useAtomValue(lossesAtom);
    const totalPlayed = useAtomValue(totalPlayedAtom);

    const unitChart = () => {
        let units = [];
        for(let i = 0; i < cardStats.length; i++){
            if(cardStats[i].cardType === "Unit"){
                let found = false;
                for(let i2 = 0; i2 < units.length; i2++){
                     if(units[i2].cardName === cardStats[i].cardName){
                         units[i2].amount += 1;
                         found = true;
                         break;
                     }
                }
                if(!found) {
                    let cs = cardStats[i];
                    cs.amount = 1;
                    units.push(cs);
                }
            }
        }
        units.sort((a, b) =>
             a.amount > b.amount ? -1 : 1);
        return units;
    }

    const actionsChart = () => {
        let actions = [];
        for(let i = 0; i < cardStats.length; i++){
            if(cardStats[i].cardType === "Action"){
                let found = false;
                for(let i2 = 0; i2 < actions.length; i2++){
                    if(actions[i2].cardName === cardStats[i].cardName){
                        actions[i2].amount += 1;
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    let cs = cardStats[i];
                    cs.amount = 1;
                    actions.push(cs);
                }
            }
        }
        actions.sort((a, b) =>
            a.amount > b.amount ? -1 : 1);
        return actions;
    }

    const manaChart = () => {
        let mana = [];
        for(let i = 0; i < cardStats.length; i++){
            if(cardStats[i].cardType === "Resource"){
                let found = false;
                for(let i2 = 0; i2 < mana.length; i2++){
                    if(mana[i2].cardName === cardStats[i].cardName){
                        mana[i2].amount += 1;
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    let cs = cardStats[i];
                    cs.amount = 1;
                    mana.push(cs);
                }
            }
        }
        mana.sort((a, b) =>
            a.amount > b.amount ? -1 : 1);
        return mana;
    }

    const piechart = () => {
        let unitval = 0;
        unitChart().map(x => unitval += x.amount);
        let actionval = 0;
        actionsChart().map(x => actionval += x.amount);
        let manaval = 0;
        manaChart().map(x => manaval += x.amount);
        return [{name: "Units", value: unitval, color: "indigo"}, {name: "actions",value: actionval, color: "red"}, {name: "mana", value: manaval, color: "green"} ]
    }

   return (
        <div className={"flex flex-wrap h-full w-full justify-center items-center gap-3 overflow-hidden p-4 border-[#51b78c] border-t"}>
            <Card withBorder radius="md">
                <Group justify="space-between">
                    <Text>Totals</Text>
                </Group>
                <SimpleGrid cols={2} mt="md">
            <Paper withBorder style={{justifyContent: "center", alignContent: "center", alignItems: "center"}} radius="md" p="lg" w={400} h={300} key={"depoys"}>
                <div>Match Statistics</div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Matches Played
                    </div>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{totalPlayed}</div>
                </div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Matches Won
                    </div>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{wins}</div>
                </div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Matches Lost
                    </div>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{losses}</div>
                </div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Win Ratio
                    </div>
                    <div
                        className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{wins}/{totalPlayed}</div>
                </div>
            </Paper>

            <Paper withBorder radius="md" p="lg" key={"depoys"} style={{justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                <PieChart withLabelsLine labelsPosition="inside" labelsType="value" withLabels tooltipDataSource="segment" mx="auto" data={piechart()} withTooltip />
                <List className={"flex flex-col w-[200px] justify-self-center self-center px-2 text-sm"}>
                    <List.Item className={"flex flex-row justify-center items-center pt-2 px-2 text-sm text-indigo-400"}>
                        {"Unit Deploys: " +  piechart()[0].value}
                    </List.Item>
                    <List.Item className={"flex flex-row justify-center items-center px-2 text-sm text-red-500"}>
                        {"Action Deploys: " +  piechart()[1].value}
                    </List.Item>
                    <List.Item className={"flex flex-row justify-center items-center px-2 text-sm text-green-500"}>
                        {"Mana Deploys: " +  piechart()[2].value}
                    </List.Item>
                    <List.Item className={"flex flex-row justify-center items-center px-2 text-sm text-gray-400"}>
                        {"Total Deploys: " + (piechart()[0].value + piechart()[1].value + piechart()[2].value)}
                    </List.Item>
                </List>
            </Paper>
                </SimpleGrid>
            </Card>

            <Card withBorder radius="md">
                <Group justify="space-between">
                    <Text>Card Deploys</Text>
                </Group>
                <SimpleGrid cols={3} mt="md">
            <Paper withBorder style={{justifyContent: "center", alignContent: "center", alignItems: "center"}} radius="md" p="lg" w={400} h={300}>
                <BarChart
                    h={280}
                    data={unitChart()}
                    withXAxis={false}
                    dataKey="cardName"
                    series={[
                        {label: "Unit Deploys", name: 'amount', color: 'indigo'},
                    ]}
                    tickLine="y"
                    withLegend
                    legendProps={{verticalAlign: 'bottom', height: 50}}
                />
            </Paper>
            <Paper withBorder style={{justifyContent: "center", alignContent: "center", alignItems: "center"}} radius="md" p="lg" w={400} h={300}>
                <BarChart
                    h={280}
                    data={actionsChart()}
                    withXAxis={false}
                    dataKey="cardName"
                    series={[
                        {label: "Action Deploys", name: 'amount', color: 'red'},
                    ]}
                    tickLine="y"
                    withLegend
                    legendProps={{verticalAlign: 'bottom', height: 50}}
                />
            </Paper>
            <Paper withBorder style={{justifyContent: "center", alignContent: "center", alignItems: "center"}} radius="md" p="lg" w={400} h={300}>
                <BarChart
                    h={280}
                    data={manaChart()}
                    withXAxis={false}
                    dataKey="cardName"
                    series={[
                        {label: "Mana Deploys", name: 'amount', color: 'green'},
                    ]}
                    tickLine="y"
                    withLegend
                    legendProps={{verticalAlign: 'bottom', height: 50}}
                />
            </Paper>
                </SimpleGrid>
            </Card>
        </div>
    )
}