import React, {useCallback, useEffect, useState} from "react";
import {bordercolor} from "../theme";
import CardDeploys from "./carddeploys";
import MatchReports from "./matchreports";
import StatsDash from "./statsdash";
import { supabase } from "../utils/supabase";
import {useAppContext} from "../contexts/appContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import {ActionIcon, Button} from "@mantine/core";
import {useAtom, useAtomValue} from "jotai/index";
import {
    cardStatsAtom,
    cardTotalStatsAtom,
    loadingRMAtom,
    loadingStatsAtom, lossesAtom,
    reportsAtom, totalPlayedAtom, winsAtom
} from "../atoms/statisticsAtoms";
import {userIdAtom} from "../atoms/appAtoms";
const selbuttonCol = "bg-[#4CB190C3] border border-[#3b6f5e26]"
const buttonCol = "bg-[#3b6f5e26] border border-[#4CB1904A]"


export default function Statistics() {
    const [openStatPage, setOpenStatPage] = useState("dash");
    const [hoverId, setHoverID] = useState("");
    const {cardsdate} = useAppContext();
    const [initial, setInitial] = useState(true);
    const [reports, setReports] = useAtom(reportsAtom);
    const [loadingRM, setLoadingRM] = useAtom(loadingRMAtom);
    const [loadingStats, setLoadingStats] = useAtom(loadingStatsAtom);
    const [cardStats, setCardStats] = useAtom(cardStatsAtom);
    const [cardTotalStats, setCardTotalStats] = useAtom(cardTotalStatsAtom);
    const [wins, setWins] = useAtom(winsAtom);
    const [losses, setLosses] = useAtom(lossesAtom);
    const [totalPlayed, setTotalPlayed] = useAtom(totalPlayedAtom);
    const id = useAtomValue(userIdAtom)

    const GetMatchDeploys = useCallback(async (newReports) => {
        let reps = [];
        let totwins = 0;
        let totlosses = 0;
        let tot = 0;


        if (newReports && newReports.length > 0) {
            for (let i = 0; i < newReports.length; i++) {
                if (newReports[i].winnerID === id) totwins++;
                else totlosses++;
                tot++;

                try {
                    const { data, error } = await supabase
                        .from('cardstats')
                        .select()
                        .eq("matchid", newReports[i].MatchID);

                    let rep = newReports[i];
                    rep.rcardsDeployed = [];
                    rep.actionsDeployed = [];
                    rep.unitDeployed = [];

                    if (data && data.length > 0) {
                        for (let i2 = 0; i2 < data.length; i2++) {
                            switch (data[i2].cardType) {
                                case "Action":
                                    rep.actionsDeployed.push(data[i2]);
                                    break;
                                case "Unit":
                                    rep.unitDeployed.push(data[i2]);
                                    break;
                                case "Resource":
                                    rep.rcardsDeployed.push(data[i2]);
                                    break;
                            }
                        }
                    }
                    reps.push(rep);
                } catch (error) {
                    console.error(error);
                }
            }

            setReports(reps);
            setWins(totwins);
            setLosses(totlosses);
            setTotalPlayed(tot);
        }
        setLoadingRM(false);
    }, [setLoadingRM, setReports, setWins, setLosses, setTotalPlayed, id]);

    const GetMatchReports = useCallback(async () => {
        setLoadingRM(true);
        try {
            const { data, error } = await supabase
                .from('matchreports')
                .select()
                .or(`pOneID.eq.${id},pTwoID.eq.${id}`)

            if(data && data.length > 0) {
                GetMatchDeploys(data);
            } else {
                GetMatchDeploys([]);
                setLoadingRM(false);
            }
        } catch (error) {
            console.error(error);
            setLoadingRM(false);
        }
    }, [id, setLoadingRM, GetMatchDeploys]);

    const GetCardStats =  useCallback(async () => {
        setLoadingStats(true);
        const {data, error} = await supabase
            .from('cardstats')
            .select()
            .eq("user_id", id);

        let rep = {};
        rep.rcardsDeployed = [];
        rep.actionsDeployed = [];
        rep.unitDeployed = [];

        if (data && data.length > 0) {
            data.forEach(dep => {
                let found = false;
                switch (dep.cardType) {
                    case "Resource":
                        for (let i = 0; i < rep.rcardsDeployed.length; i++) {
                            let card = rep.rcardsDeployed[i];
                            if (dep.cardid === card.cardid) {
                                const updatedCard = {
                                    ...dep,
                                    amount: card.amount + 1
                                };
                                rep.rcardsDeployed[i] = updatedCard;
                                found = true;
                            }
                        }
                        ;
                        if (!found) rep.rcardsDeployed.push({
                            ...dep,
                            amount: 1
                        });
                        break;
                    case "Action":
                        for (let i = 0; i < rep.actionsDeployed.length; i++) {
                            let card = rep.actionsDeployed[i];
                            if (dep.cardid === card.cardid) {
                                const updatedCard = {
                                    ...dep,
                                    amount: card.amount + 1
                                };
                                rep.actionsDeployed[i] = updatedCard;
                                found = true;
                            }
                        }
                        if (!found) rep.actionsDeployed.push({
                            ...dep,
                            amount: 1
                        });
                        break;
                    case "Unit":
                        for (let i = 0; i < rep.unitDeployed.length; i++) {
                            let card = rep.unitDeployed[i];
                            if (dep.cardid === card.cardid) {
                                const updatedCard = {
                                    ...dep,
                                    amount: card.amount + 1
                                };
                                rep.unitDeployed[i] = updatedCard;
                                found = true;
                            }
                        }
                        ;
                        if (!found) rep.unitDeployed.push({
                            ...dep,
                            amount: 1
                        });
                        break;
                    default:
                        break;
                }
            });
            setCardStats(data);
        } else setCardStats([]);
        setCardTotalStats(rep);
        setLoadingStats(false);
    },[id, setCardStats, setCardTotalStats, setLoadingStats] );

    useEffect(() => {
        if(initial) {
            GetMatchReports();
            GetCardStats();
            setInitial(false);
        }
    }, [initial]);

    const menubutton = (page, title) => {
        return (

            <Button variant={openStatPage === page ? "light" : "default"} onClick={() => {
                setOpenStatPage(page)
            }}>
                {title}
            </Button>)
    }

    if(loadingRM || loadingStats){
        return (<div className={"flex flex-col h-full w-full overflow-hidden"}>
                    <div className={"flex flex-col h-full w-full overflow-hidden"}>
                        Loading Statistics...
                    </div>
                </div>)
    } else 
    return (
            <div className={"flex flex-col h-full w-full overflow-hidden"} onPointerDown={(e) => { if(e.button === 0 && hoverId !== "") setHoverID(""); }}>
                <div className={"flex flex-col h-full w-full overflow-hidden"}>
                    <div className={"flex flex-row h-14 w-full justify-center items-center gap-4 text-[#51b78c]  bg-[#0a0f13]"}>
                        {menubutton("dash", "Dashboard")}
                        {menubutton("deploys", "Card Deployments")}
                        {menubutton("matches", "Match Reports")}
                        <ActionIcon onClick={() => {
                            GetMatchReports();
                            GetCardStats();
                        }} >
                            <RefreshIcon />
                        </ActionIcon>
                    </div>
                    {openStatPage === "deploys" && <CardDeploys hoverId={hoverId} setHoverID={setHoverID}/>}
                    {openStatPage === "matches" && <MatchReports GetMatchReports={GetMatchReports} hoverId={hoverId} setHoverID={setHoverID}/>}
                    {openStatPage === "dash" && <StatsDash/>}
                </div>
                {hoverId !== "" &&
                    <div onMouseLeave={() => setHoverID("")}
                         className={"absolute flex flex-col min-h-40 h-auto w-[400px] left-4 top-[20%] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                        <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                             onMouseLeave={() => setHoverID("")}
                             src={"/cards/" + hoverId + ".png?key=" + cardsdate}/>
                    </div>}
            </div>
    )
}