import React, {useRef} from "react";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import PatchNoteDisplay from "./patchnotedisplay";
import Button from "@mui/material/Button";
import axios from "axios";
import PatchSample from "../pages/patchnotesdraft";
import { toPng } from 'html-to-image';
import {useApp} from "../hooks/useApp";
import {useAtom, useAtomValue} from "jotai/index";
import {editEnabledAtom, webhookURLAtom} from "../atoms/newsAtoms";

export default function ArticleView(props) {
    const {art, setEditing, ind} = props;
    const {session, isAdmin} = useApp();
    const [editEnabled, setEditEnabled] = useAtom(editEnabledAtom);
    const elementRef = useRef(null);
    const webhookURL = useAtomValue(webhookURLAtom);

    async function SubmitDelete() {
        if(!session || !isAdmin) return;
        const {error} = await supabase.from('newsfeed')
            .delete()
            .eq('id', art.id);

        if(error){
            toast.error('Failed to delete.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Deleted news article successfully.', {
            duration: 4000,
            position: 'bottom-right',
        });
    }

    const flist = (list) => {
        let ls = "";
        if(list.length < 1) return "";
        list.map((feature, ind) => (
            ls += "- " + feature + "\n"
        ));
        return ls;
    }

    async function SubmitDiscord(){
        let embed = {};
        if(art.type === "Patch Notes"){
            let pn = JSON.parse(art.content);
            embed = {
                color: 0x0099ff,
                title: art.title,
                url: 'https://play.manacaster.com',
                "thumbnail": {
                    "url": "https://play.manacaster.com/logo512.png"
                },
                author: {
                    name: "Manacaster Games",
                    icon_url: "https://play.manacaster.com/logo192.png",
                    url: 'https://play.manacaster.com',
                },
                description: "A new patch has been released! \n\n" + pn.description,
                fields: [
                    { name: 'Known Issues (' + pn.issues.length + ")"  , value: flist(pn.issues) },
                    { name: 'New Features (' + pn.features.length + ")" , value: flist(pn.features) },
                    { name: 'Improvements (' + pn.improvements.length + ")" , value: flist(pn.improvements)  },
                    { name: 'Fixes (' + pn.fixes.length + ")" , value: flist(pn.fixes)  },
                    { name: 'Tester Instructions' , value: pn.notes ? pn.notes : "" },
                ],
                timestamp: new Date().toISOString(),
                footer: {
                    text: 'Manacaster Update',
                    iconURL: ""
                }
            };
        } else {

        }
        try {
            await axios.post(webhookURL, {username: "Manacaster Games",
                avatarURL: "https://play.manacaster.com/logo192.png",
                content: "@everyone",
                tts: false,
                embeds: [embed]
            });
        } catch (error) {
            console.error('Error sending message to Discord:', error);
            toast.error('Error occurred posting to discord webhook.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    if(art.staged && !editEnabled){
        return (<></>)
    }
    else return (
        <article className="flex flex-row h-auto w-full">
            <div className="xl:flex flex-col h-auto w-full border-slate-700 border-b bg-black bg-opacity-20 px-2">
                <div className="grow py-4 px-4">
                        {art.type === "Patch Notes" ?
                            <PatchNoteDisplay ind={ind} art={art}/> :
                            <>
                                <header>
                                    <h2 className="text-2xl font-bold mb-3">{art.title}</h2>
                                </header>
                                {art.content}
                            </>
                        }
                </div>

                {session && isAdmin && editEnabled && <div className="flex flex-row w-full h-10 shrink-0 px-4 justify-center items-center">
                    <div style={{position: "absolute", right: -50000}}>
                       
                    </div>
                    <Button
                        className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            SubmitDiscord();
                        }}
                    >
                        Post Discord
                    </Button>
                    <Button
                        className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            setEditing(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            SubmitDelete();
                        }}
                    >
                        Delete
                    </Button>

                </div>}
                <div className={"flex h-10 w-full"}></div>
            </div>
        </article>
    )
}
