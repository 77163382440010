import {bordercolor} from "../theme";
import React, {useCallback, useState} from "react";
import ActiveLogEntry from "./activeLogEntry";
import MatchLogEntry from "./matchlogentry";
import MatchLogList from "./matchlogList";
import {ScrollArea} from "@mantine/core";
import {useAtom, useAtomValue} from "jotai/index";
import {
    activeReportAtom,
    activeSortAtom,
    activeTypeAtom,
    loadingRMAtom,
    sortedStatsReportsAtom
} from "../atoms/statisticsAtoms";
import {supabase} from "../utils/supabase";

export default function MatchReports(props){
    const {GetMatchReports} = props;
    const loadingRM = useAtomValue(loadingRMAtom);
    const [activeSort, setActiveSort] = useAtom(activeSortAtom);
    const [activeType, setActiveType] = useAtom(activeTypeAtom);
    const [hoverId, setHoverID] = useState("");
    const [activeReport, setActiveReport] = useAtom(activeReportAtom);
    const sortedReports = useAtomValue(sortedStatsReportsAtom);

    const DeleteReport = useCallback(async (report) => {
        try {
            const { error } = await supabase
                .from('matchreports')
                .delete()
                .eq('MatchID', report.MatchID);

            if (!error) {
                await GetMatchReports();
            }
        } catch (error) {
            console.error(error);
        }
    }, [GetMatchReports]);

    return (
        <div className={"flex flex-row h-full w-full gap-3 overflow-hidden border-[#51b78c] border-t"}>
            <MatchLogList GetReports={GetMatchReports} admin={false} activeType={activeType} setActiveType={setActiveType} activeReport={activeReport} activeSort={activeSort} setActiveSort={setActiveSort} setActiveReport={setActiveReport} loadingRM={loadingRM} SortedReports={sortedReports} />
            <div className="flex flex-col h-full w-full overflow-hidden">
                    {loadingRM ?
                        <div className="w-full lg:w-4/5 mx-auto">
                            <div className="flex flex-col gap-4 w-52">
                                <div className="skeleton h-32 w-full"></div>
                                <div className="skeleton h-4 w-28"></div>
                                <div className="skeleton h-4 w-full"></div>
                                <div className="skeleton h-4 w-full"></div>
                            </div>
                            <p>Loading Match Reports..</p>
                        </div> :
                        <div className="flex flex-col w-full h-full mx-auto overflow-hidden">
                            {!activeReport &&
                                <ScrollArea h={"100%"}>
                                    <div className={"flex flex-col w-full h-auto pl-2 pr-6"}>
                                        {sortedReports && sortedReports.map((el, ind) =>
                                            <MatchLogEntry activeType={activeType} user={true} DeleteReport={DeleteReport} activeReport={activeReport} key={ind} ind={ind} report={el}/>)}
                                    </div>
                                </ScrollArea>}
                            {activeReport && <ActiveLogEntry user={true} DeleteReport={DeleteReport} activeReport={activeReport} setHoverID={setHoverID} hoverId={hoverId}/>}
                        </div>}
                </div>
            {hoverId !== "" &&
                <div
                    className={"absolute pointer-events-none flex flex-col min-h-40 h-auto w-[400px] left-4 top-[20%] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                         src={"/cards/" + hoverId + ".png"}/>
                </div>}
        </div>
    )
}