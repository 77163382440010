import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import LabelIcon from '@mui/icons-material/Label';
import {Tabs, rem, List, ThemeIcon} from '@mantine/core';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import BugReportIcon from '@mui/icons-material/BugReport';
import ConstructionIcon from '@mui/icons-material/Construction';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export default function PatchNoteDisplay(props) {
    const { art, ind } = props;

    const iconStyle = { width: rem(12), height: rem(12) };


    const cont = JSON.parse(art.content);
    return (
        <div className={"flex flex-col h-auto w-full text-textprimary"}>
            <header className={"flex flex-row h-10 w-full justify-between gap-2"}>
                <div className={"flex flex-row w-auto gap-4 h-full"}>
                    <h2 className="flex flex-wrap h-full w-auto justify-start items-end text-xl font-bold mb-3">{art.title}</h2>
                    <div className={"flex flex-wrap h-full w-auto justify-start items-end text-sm"}>{"Released at " + (cont.rdate && cont.rdate)}</div>
                </div>
            </header>

            <div className={"flex flex-col h-auto w-full justify-start items-start gap-2"}>
                <div className={"flex flex-wrap h-auto py-2"}>{cont.description && cont.description}</div>
                <div className={"flex flex-row cursor-pointer h-10 w-full justify-start items-center px-4 gap-2 font-semibold select-none"}>
                    Patch Notes
                </div>
                <div className={"flex flex-col h-auto py-2 w-[90%] px-2"}>
                    <Tabs defaultValue="issues">
                        <Tabs.List>
                            <Tabs.Tab value="issues" leftSection={<BugReportIcon style={iconStyle} />}>
                                <Typography>Known Issues ({cont.issues && cont.issues.length})</Typography>
                            </Tabs.Tab>
                            <Tabs.Tab value="features" leftSection={<NewReleasesIcon style={iconStyle} />}>
                                <Typography>New Features ({cont.features && cont.features.length})</Typography>
                            </Tabs.Tab>
                            <Tabs.Tab value="improvements" leftSection={<TaskAltIcon style={iconStyle} />}>
                                <Typography>Improvements ({cont.improvements && cont.improvements.length})</Typography>
                            </Tabs.Tab>
                            <Tabs.Tab value="fixes" leftSection={<ConstructionIcon style={iconStyle} />}>
                                <Typography>Fixes ({cont.fixes && cont.fixes.length})</Typography>
                            </Tabs.Tab>
                        </Tabs.List>
                        <div className={"h-6 w-full"}></div>
                        <Tabs.Panel value="issues">
                            <List withPadding spacing="md"
                                  size="sm"
                                  center
                                  icon={<HorizontalRuleIcon style={{ width: rem(16), height: rem(16) }} />}>
                                {cont.issues && cont.issues.length > 0 && cont.issues.map((issue, ind) => (
                                    <List.Item key={ind}>
                                        {issue}
                                    </List.Item>
                                ))}
                            </List>
                        </Tabs.Panel>

                        <Tabs.Panel value="features">
                            <List withPadding spacing="md"
                                  size="sm"
                                  center
                                  icon={<HorizontalRuleIcon style={{ width: rem(16), height: rem(16) }} />}>
                                {cont.features && cont.features.length > 0 && cont.features.map((feature, ind) => (
                                    <List.Item key={ind} >
                                        {feature}
                                    </List.Item>
                                ))}
                            </List>
                        </Tabs.Panel>

                        <Tabs.Panel value="improvements">
                            <List withPadding spacing="md"
                                  size="sm"
                                  center
                                  icon={<HorizontalRuleIcon style={{ width: rem(16), height: rem(16) }} />}>
                                {cont.improvements && cont.improvements.length > 0 && cont.improvements.map((improvement, ind) => (
                                    <List.Item key={ind}>
                                        {improvement}
                                    </List.Item>
                                ))}
                            </List>
                        </Tabs.Panel>

                        <Tabs.Panel value="fixes">
                            <List withPadding spacing="md"
                                  size="sm"
                                  center
                                  icon={<HorizontalRuleIcon style={{ width: rem(16), height: rem(16) }} />}>
                                {cont.fixes && cont.fixes.length > 0 && cont.fixes.map((fix, ind) => (
                                    <List.Item key={ind}>
                                        {fix}
                                    </List.Item>
                                ))}
                            </List>
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}