import React, {Suspense, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useDownload} from "./useDownload";
import { json2csv } from 'json-2-csv';
import toast from "react-hot-toast";
import {supabase} from "../utils/supabase";
import {useAtom} from "jotai/index";
import {decksAtom, loadingDecksAtom} from "../atoms/adminAtoms";

export default function DecksList(props) {
    const [decks, setDecks] = useAtom(decksAtom);
    const [loadingDecks, setLoadingDecks] = useAtom(loadingDecksAtom);

    useEffect(() => {
        LoadDecks();
    }, []);

    const LoadDecks = async () => {
        setLoadingDecks(true);
        const {data} = await supabase
            .from('decks')
            .select();
        if (data && data.length > 0) {
            setDecks(data);
        } else {
            setDecks([]);
        }
        setLoadingDecks(false);
    };

    return (
        <div className={"flex flex-col w-full h-full p-10"}>
            <Suspense fallback={<div>Loading decks data...</div>}>
            <div className="flex overflow-y-auto w-full border border-[#51b78c] border-opacity-40">
                <table className="table w-full table-xs table-pin-rows table-pin-cols justify-center items-center">
                <DecksHeader />
                <tbody>
                {decks && decks.map((el, ind) =>
                    <DeckItem key={ind} entry={el}/>)}
                </tbody>
            </table>
            </div>
            </Suspense>
        </div>
    )
}

function DecksHeader(props) {

    return (
        <thead>
        <tr>
            <th>ID</th>
            <th>Origin</th>
            <th>DeckName</th>
            <th>Owner</th>
            <th>Description</th>
            <th>MainDeck</th>
            <th>Mana</th>
            <th>Export</th>
        </tr>
        </thead>
    )
}

const playerentrystyle = "flex-row text-center justify-center items-center border-r border-[#51b78c] border-opacity-40";

function DeckItem(props) {
    const {entry} = props;
    const { handleZip } = useDownload();
    const [cards, setCards] = useState([]);
    useEffect(() => {
        if(entry) {
            GetCardList();
        }
    }, [entry]);

    function GetCardList(){
        let co = {};
        co.names = [];
        co.cards = [];
        let ls = [];
        let ls2 = [];
        for(let i = 0; i < entry.maindeck.length; i++) {
            let nc = "cards/" + entry.maindeck[i].Id + ".png";
            ls.push(nc);
            ls2.push(entry.maindeck[i].Name);
        }
        for(let i = 0; i < entry.resourcesdeck.length; i++) {
            let nc = "cards/" + entry.resourcesdeck[i].Id + ".png";
            for(let i2 = 0; i2 < entry.resourcesdeck[i].amount; i2++){
                ls.push(nc);
                ls2.push(entry.resourcesdeck[i].Name);
            }
        }
        for(let i = 0; i < entry.bench.length; i++) {
            let nc = "cards/" + entry.bench[i].Id + ".png";
            ls.push(nc);
            ls2.push(entry.bench[i].Name);
        }
        co.cards = ls;
        co.names = ls2;
        setCards(co);
    }

    function PurgedList(clist){
        let cl = [];
        for(let i = 0; i < clist.length; i++){
            cl.push(clist[i].Name);
        }
        return cl;
    }

    return (
        <tr className={"border border-[#51b78c] border-opacity-40"}>
            <td className={playerentrystyle}>{entry.id}</td>
            <th className={playerentrystyle}>{entry.origin && entry.origin === -1 ? "New" : "Deck " + entry.origin}</th>
            <th className={playerentrystyle}>{entry.deckName}</th>
            <td className={playerentrystyle}>{entry.ownerName}</td>
            <td className={playerentrystyle}>{entry.description}</td>
            <td className={playerentrystyle}>{entry.maindeck.length}</td>
            <td className={playerentrystyle}>{entry.resourcesdeck.length}</td>
            <td className={playerentrystyle}>
                <>
                    <Button onClick={() => {
                        handleZip(cards.cards, cards.names);
                    }} className={"btn-ghost"}>Images</Button>
                    <Button onClick={() => {
                        let nc = entry;
                        nc.maindeck = PurgedList(entry.maindeck);
                        nc.resourcesdeck = PurgedList(entry.resourcesdeck);
                        nc.bench = PurgedList(entry.bench);
                        navigator.clipboard.writeText(JSON.stringify(nc));
                        toast.success('Deck exported to clipboard in JSON format.', {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                    }
                    }>
                        JSON
                    </Button>
                    <Button onClick={() => {
                        let nc = entry;
                        nc.maindeck = PurgedList(entry.maindeck);
                        nc.resourcesdeck = PurgedList(entry.resourcesdeck);
                        nc.bench = PurgedList(entry.bench);
                        const csv = json2csv(nc);
                        navigator.clipboard.writeText(csv);
                        toast.success('Deck exported to clipboard in CSV format.', {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                    }
                    }>
                        CSV
                    </Button>
                </>
            </td>
        </tr>
    )
}