import {atom} from 'jotai';
// Base reports atom now uses the loadable version
export const reportsAtom = atom([]);

export const loadingRMAtom = atom(true);
export const loadingServersAtom = atom(false);
export const loadingDecksAtom = atom(false);
export const activeReportAtom = atom(null);
export const activeSortAtom = atom("descTime");
export const activeTypeAtom = atom("all");
export const decksAtom = atom([]);
export const allPlayersAtom = atom([]);
export const activeServersAtom = atom([]);

// Derived atom for sorted reports
export const sortedReportsAtom = atom((get) => {
  const reports = get(reportsAtom);
  const activeSort = get(activeSortAtom);
  const activeType = get(activeTypeAtom);

  if (!reports) return [];

  let sortedReports = [...reports];

  switch (activeType) {
    case "draftpvp":
      sortedReports = sortedReports.filter(report => report.matchtype === "DraftPvP");
      break;
    case "casual":
      sortedReports = sortedReports.filter(report => report.matchtype === "Casual");
      break;
    case "all":
    default:
      break;
  }

  switch(activeSort) {
    case "ascTime":
      return sortedReports.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? -1 : 1);
    case "descTime":
      return sortedReports.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? 1 : -1);
    default:
      return sortedReports;
  }
});