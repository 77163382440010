import { useAtom, useAtomValue } from 'jotai';
import { supabase } from "../utils/supabase";
import {useEffect, useCallback, useState} from 'react';
import { useAppContext } from "../contexts/appContext";
import secureLocalStorage from "react-secure-storage";
import {useApp} from "./useApp";
import {
    allUsersAtom, friendsAtom,
    incRequestsAtom, loadingFriendsAtom, loadingMessagesAtom, lobbyMessagesAtom,
    myRequestsAtom,
    readChannelAtom,
    selectedFriendAtom,
    userListAtom, usersAtom
} from "../atoms/socialAtoms";

export const useSocial = () => {
  const [lobbyMessages, setLobbyMessages] = useAtom(lobbyMessagesAtom);
  const [loadingMessages, setLoadingMessages] = useAtom(loadingMessagesAtom);
  const [loadingFriends, setLoadingFriends] = useAtom(loadingFriendsAtom);
  const [users, setUsers] = useAtom(usersAtom);
  const [friends, setFriends] = useAtom(friendsAtom);
  const [allUsers, setAllUsers] = useAtom(allUsersAtom);
  const [myRequests, setMyRequests] = useAtom(myRequestsAtom);
  const [incRequests, setIncRequests] = useAtom(incRequestsAtom);
  const [selectedFriend, setSelectedFriend] = useAtom(selectedFriendAtom);
  const [initial, setInitial] = useState(true)
  
  const { profile, session, id } = useApp();

  const userStatus = {
    user: profile ? profile.username : "",
    id: id,
    online_at: new Date().toISOString(),
  };

  const TotalCount = () => {
    return ChannelCounter() + RequestsCounter();
  };

  const ChannelCounter = () => {
    return lobbyMessages.length;
  };

  const RequestsCounter = () => {
    let am = 0;
    for(let i = 0; i < friends.length; i++){
      if(friends[i].status !== "accepted") am++;
    }
    return am;
  };


  function friendExists(friendid, ls) {
    if(!ls || ls.length === 0) return false;
    let exists = false;
    for(let i = 0; i < ls.length; i++) {
        if(ls[i].friendid === friendid || ls[i].user_id === friendid) {
            exists = true;
            break;
        }
    }
    return exists;
}

function FriendOnline(friendID){
    let found = false;
    for(let i = 0; i < allUsers.length; i++){
        if(allUsers[i].user_id === friendID){
            found = true;
            break;
        }
    }
    return found;
}

function UserData(uid){
    for(let i = 0; i < allUsers.length; i++){
        if(allUsers[i].user_id === uid) return allUsers[i];
    }
}

  const GetLobbyMessages = async () => {
    setLoadingMessages(true);
    try {
      const { data, error } = await supabase
        .from('lobbychat')
        .select();
      if(error){
        setLoadingMessages(false);
      } else {
        setLobbyMessages(data);
        setLoadingMessages(false);
      }
    } catch (error) {
      setLoadingMessages(false);
    }
  };

  const GetFriends = async () => {
    setLoadingFriends(true);
      const { data, error } = await supabase
          .from('profiles')
          .select();
      if (data.length > 0) {
          setAllUsers(data);
      } else setAllUsers([]);
    try {
      const { data } = await supabase
        .from('friends')
        .select()
        .or(`user_id.eq.${id},friendid.eq.${id}`);
      
      if (data) {
        setFriends(data);
        let inc = [];
        let out = [];
        data.forEach(item => {
          if (item.status !== "accepted") {
            if (item.user_id === id) out.push(item);
            else if (item.friendid === id) inc.push(item);
          }
        });
        setMyRequests(out);
        setIncRequests(inc);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFriends(false);
    }
  };

  function UpdateMessage(msg){
    let msgs = [];
    let found = false;
    lobbyMessages.forEach(tsk => {
        if(tsk.id === msg.id){
            msgs.push(msg);
            found = true;
        } else msgs.push(tsk);
    })
    if(!found) msgs.push(msg);
    setLobbyMessages(msgs);
}

useEffect(() => {
    const channel = supabase
        .channel('lobbychat')
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: "lobbychat"
            },
            (payload) => { GetLobbyMessages(); }
        )
        .subscribe(status => {
            if (status === 'SUBSCRIBED') {
                channel.track({ userStatus });
            }
        });

    const channel2 = supabase
        .channel('friends')
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: "friends"
            },
            (payload) => { GetFriends(); }
        ).subscribe();

    channel.on('presence', { event: 'sync' }, () => {
        const presenceState = channel.presenceState();
        const users = Object.keys(presenceState)
            .map((presenceId) => {
                const presences = presenceState[presenceId];
                return presences.map((presence) => presence.userStatus);
            }).flat();
        setUsers(users.sort());
    });

    if(session && initial && profile){
        GetLobbyMessages();
        GetFriends();
        setInitial(false);
    }
    return () => {
        supabase.removeChannel(channel);
        supabase.removeChannel(channel2);
    };
}, [session, profile]);

  return {
    lobbyMessages,
    loadingMessages,
    users,
    friends,
    myRequests,
    incRequests,
    selectedFriend,
    setSelectedFriend,
    GetLobbyMessages,
    GetFriends,
    TotalCount,
    ChannelCounter,
    RequestsCounter,
    FriendOnline,
    UserData,
    UpdateMessage
  };
};