import React, {useEffect} from "react";
import {useAppContext} from "../contexts/appContext";
import {useApp} from "../hooks/useApp";

export default function RuleBook(props) {
    const {username, session} = useApp();
    return (
        <div className={"flex flex-col h-full w-full overflow-y-auto"}>
            <section className="flex flex-col  justify-center items-center h-auto w-full py-24">
                <div className="flex flex-col container gap-2">
                    <div className="row">
                        <div className="col relative text-3xl font-bold">
                            <h1>A strategic card battle game for 2 or more players.</h1>
                        </div>
                        <p></p>
                    </div>
                    <p>Starting the Game : To begin a game, all players select a 50-card Main deck with a 12-card Mana
                        deck. Set aside the Mana deck and shuffle the Main deck. Each player starts with 20 life points,
                        and the goal is to reduce the opponent’s life to 0 by attacking with Unit cards. Randomly select
                        which player begins the game as the “Active” player with Attack Priority. Each player draws 7
                        cards from their Main deck and is then given the chance to discard and re-draw up to 7 new
                        cards.
                        <br/>
                        <br/>
                        <br/>Mana Cards: Mana cards provide the resources needed to deploy Unit & Action cards. Once per
                        turn, each player selects 1 mana card from their 12-card Mana Deck and puts it onto the board. A
                        player may look at their Mana deck at any time, but players cannot look at other players’ Mana
                        decks. Mana cards remain on the board once deployed & refresh at the start of each turn.
                        <br/>
                        <br/>
                        <br/>Unit & Action Cards: Unit cards represent personnel that can be deployed onto the board to
                        attack your opponent. Action cards represent temporary & permanent effects and are discarded
                        immediately after use. Units remain on the board until killed.
                        <br/>
                        <br/>
                        <br/>At the start of each turn, all players draw 1 card from their main deck and refresh their
                        Mana cards. All damage and “this turn” effects on Units expire. The Active player gets
                        Deployment Priority, meaning they can deploy their choice of a Unit, Action or Resource card.
                        Upon deploying a Unit or Action card, Deployment Priority passes to the next player. Deploying a
                        Resource card does not cause Deployment Priority to pass. When no players wish to deploy any
                        further cards, Combat begins. After combat, there will be another identical deployment phase
                        before the turn ends.
                        <br/>
                        <br/>
                        <br/>Combat: The Active player can use their Units to attack other players, who must defend
                        using their own Units or risk losing precious life points! Once combat begins, the Active player
                        selects which Units, if any, will attack the opponent. The Unit becomes tapped upon attacking,
                        meaning it won’t be available to defend next turn. For each attacking Unit, the opponent must
                        select whether to defend with their own Units, or accept the incoming damage. Pro Tip: The
                        defending player can use two of their own Units to block a single attacking Unit. This is called
                        “Multi-Blocking” and it’s very important!
                        <br/>
                        <br/>
                        <br/>*Mulligans: The player selects which cards from their starting hand they don’t want to
                        keep, puts those cards on the bottom of their deck, and draws that many new cards. This process
                        may be repeated a total of 3 times, and no more than 7 total cards may be replaced this way.
                        Reshuffle the main deck, including the unwanted cards, before beginning the game.
                    </p>
                    <h2>Keyword Abilities</h2>
                    <p>TOXIC: After this is dealt damage by another Unit, that other Unit gets permanent -1 ATK & -1
                        DEF.
                        <br/>
                        <br/>
                        <br/>HEAVY: When this Unit is blocked, any damage done to the defending Unit in excess of its
                        DEF is passed on to the defending enemy player. This is called Overkill damage.
                        <br/>
                        <br/>
                        <br/>ALERT: This remains untapped after attacking, and therefore can block the following turn.
                        When this blocks a Unit with Heavy, its DEF is counted double when calculating Overkill damage.
                        <br/>
                        <br/>
                        <br/>PARRY: Each time this gets damage from an enemy Unit, this Unit gains DEF equal to the
                        lesser of its own ATK and the Incoming Damage.
                        <br/>
                        <br/>
                        <br/>FIRST STRIKE: Units with First Strike deal damage to their enemies before receiving damage
                        – unless the enemy Unit also has First Strike or Twinstrike.
                        <br/>
                        <br/>
                        <br/>TWINSTRIKE: This Unit does damage twice in combat, once at the First Strike stage and once
                        at the Standard Damage stage.
                        <br/>
                        <br/>
                        <br/>REVENGE: Each time this Unit gets combat damage from an enemy Unit, that enemy Unit loses
                        DEF equal to the lesser of the Revenge Unit’s DEF and the quantity of Incoming Damage. This DEF
                        decrease lasts until the end of the current turn.
                        <br/>
                        <br/>
                        <br/>LEECH: When this does combat damage to another Unit, you gain Life Points equal to the
                        damage done by the lesser of the Defending Unit’s DEF, or the Incoming Damage. If damaging a
                        player, gain life equal to the damage.
                        <br/>
                        <br/>
                        <br/>EXTRACT: When this gets combat damage from another Unit, you gain Life Points equal to the
                        lesser of the incoming damage and the DEF of the Unit with Extract.
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>RAPID: This Unit may attack on the turn it is deployed. ( Units without this keyword
                        cannot. )
                        <br/>
                        <br/>RALLY: When this Unit’s owner deploys another Unit, this gets +1 ATK & +1 DEF until end of
                        turn.
                        <br/>
                        <br/>FOCUS: When this Unit’s owner deploys an Action, this gets +2 ATK until end of turn.
                        <br/>
                        <br/>AIRBORNE: This Unit can only be blocked by other Units with Airborne or Anti-Air.
                        <br/>
                        <br/>ANTI-AIR: This Unit can block Units with Airborne.
                        <br/>
                        <br/>BLEED 1: At the start of each turn, you lose 1 life.
                        <br/>
                        <br/>DECOY*: When this Unit attacks, it must be blocked by at least 1 Unit.
                        <br/>
                        <br/>HUNTER*: When this Unit attacks, it cannot be multi-blocked.
                        <br/>
                        <br/>*Expansion content, not yet unlockable :)
                    </p>
                    <h1 className="h2">Steps of Combat</h1>
                    <p>Select attackers.
                        <br/>
                        <br/>Resolve trigger batch for “when unit(s) attack”.
                        <br/>Select blockers.
                        <br/>Resolve trigger batch for “when unit(s) are blocked”. & “are unblocked”
                        <br/>
                        <br/>Resolve First Strike damage.
                        <br/>Resolve trigger batch for “when unit(s) die” if any units just died.
                        <br/>Check if defender player is still alive!
                        <br/>
                        <br/>
                        <br/>Resolve regular damage.
                        <br/>Resolve trigger batch for “when unit(s) die” if any units just died.
                        <br/>Check if defender player is still alive!
                        <br/>Proceed to Deployment Phase 2.
                        <br/>
                    </p>
                    <h1 className="h2">Order of Operations</h1>
                    <p>There are some situations where multiple triggers are activated by a single game event. For
                        example, when an Action deals damage to ALL Units and kills multiple Units which each have “When
                        this dies” triggers. Because these triggers all activate at the same time, there is an order of
                        operations to determine which happens first.
                        <br/>
                        <br/>
                        <br/>A group of triggers that activate from the same root event are called a “batch”. If any
                        subsequent triggers activate in the course of this order of operations, the new triggers create
                        a new batch, to be resolved after the current batch is done. On an Action card, the effects
                        should be resolved in the sequence written on the card.
                        <br/>
                        <br/>
                        <br/>Gain Life
                        <br/>Draw Cards and Discard Cards
                        <br/>Apply beneficial Unit effects not requiring a choice.
                        <br/>Apply beneficial Unit effects requiring a player’s choice, following the turn sequence when
                        multiple players have beneficial triggers.
                        <br/>Apply negative Unit effects not requiring a choice.
                        <br/>Apply negative Unit effects requiring a player’s choice, again following turn sequence.
                        <br/>Deal damage.
                        <br/>Apply loss-of-life.
                    </p>
                </div>
            </section>
        </div>
    )
}