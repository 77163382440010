import React, {useState, useCallback, useEffect} from "react";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {Link} from "react-router-dom";
import {useDbContext} from "../contexts/dbContext";
import SortMenu, {FilterMenu} from "./sortmenu";
import {Print} from "@mui/icons-material";
import {Button, Input, Switch, CloseButton, SegmentedControl, ActionIcon, Menu} from "@mantine/core";
import {dbstyle} from "./dbstylings";
import Tooltip from "@mui/material/Tooltip";
import {sendClick} from "./vuplexinteract";
import debounce from 'lodash/debounce';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DbNavBar(props) {
    const {

        setSt,
        st,
        dpOpen,
        setDpOpen,
        cardsOnly,
        ingame
    } = props;
    const {
        swapDisplayMode,
        DisplayMode,
        setSortType,
        setSearchText,
        showOwn,
        setShowOwn,
        hideAdded,
        setHideAdded,
        hideIncomplete,
        setHideIncomplete,
        deckWindow,
        setDeckWindow,
        cardTooltips,
        setCardTooltips,
    } = useDbContext();

    const startsearch = useCallback(
        debounce((st) => {
            setSearchText(st);
        }, 300),
        [st]
    );

    useEffect(() => {
        startsearch(st);
    }, [st])

    function DeckFilters(){
        const [opened, setOpened] = useState(false);
        return (
            <Menu shadow="md" width={200} opened={opened}>
                <Menu.Target>
                    <Button color={opened ? "green" : "rgba(82, 82, 82, 1)"} leftSection={<DisplaySettingsIcon />} onClick={() => {
                        setOpened(!opened);
                        sendClick();
                    }}>
                        Filters
                    </Button>
                </Menu.Target>
                <Menu.Dropdown onMouseLeave={() => setOpened(false)}>
                    <Menu.Item>
                        <Switch
                            label="Show Own"
                            checked={showOwn}
                            onChange={(event) => setShowOwn(event.currentTarget.checked)}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Switch
                            label="Hide Added"
                            checked={hideAdded}
                            onChange={(event) => setHideAdded(event.currentTarget.checked)}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Switch
                            label="Hide Incomplete"
                            checked={hideIncomplete}
                            onChange={(event) => setHideIncomplete(event.currentTarget.checked)}
                        />
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        )
    }

    return (
        <div className={dbstyle.headercolors + " flex w-full items-center justify-between border-b h-auto lg:h-16 flex-row bg-opacity-40 "}>
            <div className={"flex flex-row w-full h-auto lg:h-14 items-center justify-between p-2"}>
                <div className={"flex flex-row w-full h-12 lg:h-full items-center justify-between p-2"}>
                    <div className={"flex flex-row w-1/3 h-full justify-start items-center gap-2"}>
                        {!deckWindow ? <SortMenu setSortType={setSortType} DisplayMode={DisplayMode}/> :
                            <Tooltip title={"Filter options"}>
                                <Button onClick={() => { setDeckWindow(false);}} leftSection={<DisplaySettingsIcon />} variant="default">
                                    Close Public Decks
                                </Button>
                            </Tooltip>
                        }
                        {!deckWindow && <Input placeholder="Search name or text.."
                                value={st}
                                onChange={(e) => setSt(e.currentTarget.value)}
                                rightSectionPointerEvents="all"
                                rightSection={
                                    <CloseButton
                                        aria-label="Clear input"
                                        onClick={() => {
                                            setSt('');
                                            setSearchText("");
                                        }
                                    }
                                        style={{ display: st ? undefined : 'none' }}
                                        />}
                                    />}
                        {!deckWindow ? <FilterMenu /> : <DeckFilters />}
                    </div>
                    <div className={"flex flex-row w-1/3 h-12 lg:h-full items-center justify-around p-2"}>
                        {!deckWindow ?  <SegmentedControl
                            value={DisplayMode}
                            onChange={swapDisplayMode}
                            data={[
                                { label: 'All', value: 'All' },
                                { label: 'Units', value: 'Units' },
                                { label: 'Actions', value: 'Actions' },
                                { label: 'Mana', value: 'Mana' },
                            ]}
                        /> :
                            <p>Public Decks</p>}
                    </div>
                    <div className={"flex flex-row w-1/3 h-full lg:h-full gap-2 items-center justify-end"}>
                        {!deckWindow &&<Switch
                            className="mr-2"
                            label="Card Tooltips"
                            checked={cardTooltips}
                            onChange={(event) => setCardTooltips(event.currentTarget.checked)}
                        />}
                        {!deckWindow && !ingame && !cardsOnly &&  <Tooltip title={"Create printable card Sheet for active deck"}><Link to={"/cardsheet"}>
                            <ActionIcon size={35} variant="default" aria-label="Sort menu">
                                <Print />
                            </ActionIcon>
                        </Link></Tooltip>}
                        {!cardsOnly &&  <Tooltip title={"Deck creation and management"}><Button color={dpOpen ? "green" : "rgba(82, 82, 82, 1)"} onClick={() => {
                            sendClick();
                            setDpOpen(!dpOpen);
                        }} leftSection={<ViewModuleIcon />} >
                            Deck Management
                        </Button></Tooltip>}
                    </div>
                </div>
            </div>
        </div>
    )
}