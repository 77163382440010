import { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Input,
    Typography,
    Alert,
    CircularProgress,
    Tabs,
    Tab,
    Box,
    Button,
    Grid,
    Paper,
    TextField,
} from "@mui/material";
import Modal from "react-modal";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useApp} from "../hooks/useApp";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function AccountPage(props) {
    const { id, session, isAdmin, profile, GetProfile } = useApp();
    let email = session ? session.user.user_metadata.email : "";
    const [tempEmail, setTempEmail] = useState(email);
    const [currentPass, setCurrentPass] = useState("");
    const [tempPass1, setTempPass1] = useState("");
    const [tempPass2, setTempPass2] = useState("");
    const [passwordsmatch, setPasswordsMatch] = useState(false);
    const [fieldsComplete, setFieldsComplete] = useState(false);
    const [passwordTab, setPasswordTab] = useState(false);
    const [is2FAModalOpen, setIs2FAModalOpen] = useState(false);
    const [twoFactorStatus, setTwoFactorStatus] = useState(null);
    const [qrCodeImage, setQRCodeImage] = useState("");
    const [qrSecret, setQRSecret] = useState("");
    const [loading2FA, setLoading2FA] = useState(false);
    const [authCodeOrBackupCode, setAuthCodeOrBackupCode] = useState("");

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function UpdatePassword() {
        const { data, error } = await supabase.auth.updateUser({
            password: tempPass1
        })
        if(error) {
            toast.error("Failed to change password.");
        } else {
            setCurrentPass("");
            setTempPass1("");
            setTempPass2("");
            setPasswordTab(false);
            toast.success("Password change saved successfully.");
        }
    }

    useEffect(() => {
        if (!tempEmail.includes("@") || !tempEmail.includes(".") || tempEmail === email) {
            setFieldsComplete(false);
        } else {
            setFieldsComplete(true);
        }
    }, [tempEmail]);

    useEffect(() => {
        if (tempPass1 === tempPass2) {
            if (tempPass1.length > 7) setPasswordsMatch(true);
            else setPasswordsMatch(false);
        } else setPasswordsMatch(false);
    }, [tempPass1, tempPass2]);

    async function UpdateAccount(_email) {
            const {error} = await supabase.auth.updateUser({
            email: _email});
            if (error) {
                toast.error('Failed to update email - ' + error.message, {
                    duration: 4000,
                    position: 'bottom-right',
                });
            } else {
                GetProfile(id, profile.username, _email);
                toast.success("Email address changed successfully, a verification email has been sent to the new email address.");
            }
    }

    return (
        <div className={"flex flex-col w-full h-full justify-center items-center"}>
            <Modal
                isOpen={is2FAModalOpen}
                onRequestClose={() => setIs2FAModalOpen(false)}
                contentLabel="2FA management"
                className="Modal"
                overlayClassName="Overlay"
            >
                {loading2FA ? (
                    <CircularProgress />
                ) : twoFactorStatus ? (
                    <div>
                        <p>To disable 2FA, enter auth/backup code</p>
                        <input
                            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                            type="text"
                            value={authCodeOrBackupCode}
                            onChange={(e) => setAuthCodeOrBackupCode(e.target.value)}
                        />
                        <button
                            onClick={() => {

                            }}
                            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mt-3"
                        >
                            Send
                        </button>
                    </div>
                ) : (
                    <div>
                        <p>Scan this QR code with your authentication app:</p>
                        <img
                            src={qrCodeImage}
                            alt="QR Code"
                            style={{ display: "block", margin: "0 auto" }}
                        />
                        <div>
                            <Alert severity="success">
                                <Typography variant="h6">2FA ENABLED!</Typography>
                                <p>
                                    Be sure to scan the QRCode so as not to lose access to your
                                    account.
                                </p>
                                <hr />
                                <Typography variant="body2">
                                    If you have lost access, write to technical support.
                                </Typography>
                            </Alert>
                        </div>
                        <div className="mt-3">
                            <Accordion>
                                <AccordionSummary>Backup code</AccordionSummary>
                                <AccordionDetails>
                                    <p>
                                        If you have lost access to the authenticator application.
                                        You can enter this code to disable 2FA. Save the Backup code
                                        and make sure that only you have access to the Backup code:
                                    </p>
                                    <Input
                                        className="border rounded w-full py-2 px-3 text-gray-700"
                                        type="text"
                                        disabled
                                        value={qrSecret}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                )}
                <button
                    className="closeButton"
                    onClick={() => {
                        setIs2FAModalOpen(false);
                        setQRCodeImage("");
                        setQRSecret("");
                        setTwoFactorStatus(null);

                        setAuthCodeOrBackupCode("");
                    }}
                >
                    &#10005;
                </button>
            </Modal>

            <section
                className="max-w-4xl p-6 mx-auto rounded-md shadow-md bg-[#0a0f13] mt-20"
                style={{ minHeight: "350px" }}
            >
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab sx={{ color: "#fff" }} label="Account" {...a11yProps(0)} />
                            <Tab sx={{ color: "#fff" }} label="Password" {...a11yProps(1)} />
                            <Tab
                                sx={{ color: "#fff" }}
                                label="Two-factor authentication"
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={5}
                            component={Paper}
                            elevation={6}
                            sx={{
                                backgroundColor: "rgb(255 255 255 / 0%);",
                                boxShadow: "none",
                            }}
                            square
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <TextField
                                    variant="filled"
                                    color="success"
                                    fullWidth
                                    focused
                                    onChange={(e) => {
                                        setTempEmail(e.target.value);
                                    }}
                                    type="email"
                                    label="Email Address"
                                    value={tempEmail}
                                    sx={{
                                        pt: 1,
                                        pb: 1,
                                        "& .MuiFilledInput-root": {
                                            color: "#fff",
                                        },
                                    }}
                                />
                                <Button
                                    disabled={!fieldsComplete}
                                    onClick={() => {
                                        UpdateAccount(tempEmail);
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={5}
                            component={Paper}
                            elevation={6}
                            sx={{
                                backgroundColor: "rgb(255 255 255 / 0%);",
                                boxShadow: "none",
                            }}
                            square
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <TextField
                                    variant="filled"
                                    color="success"
                                    fullWidth
                                    focused
                                    onChange={(e) => {
                                        setCurrentPass(e.target.value);
                                    }}
                                    label="Current Password"
                                    value={currentPass}
                                    type="password"
                                    sx={{
                                        pt: 1,
                                        pb: 1,
                                        "& .MuiFilledInput-root": {
                                            color: "#fff",
                                        },
                                    }}
                                />

                                <TextField
                                    variant="filled"
                                    color="success"
                                    fullWidth
                                    focused
                                    onChange={(e) => {
                                        setTempPass1(e.target.value);
                                    }}
                                    label="New Password"
                                    value={tempPass1}
                                    type="password"
                                    sx={{
                                        pt: 1,
                                        pb: 1,
                                        "& .MuiFilledInput-root": {
                                            color: "#fff",
                                        },
                                    }}
                                />

                                <TextField
                                    variant="filled"
                                    color="success"
                                    fullWidth
                                    focused
                                    onChange={(e) => {
                                        setTempPass2(e.target.value);
                                    }}
                                    label="New Password Confirmation"
                                    value={tempPass2}
                                    type="password"
                                    sx={{
                                        pt: 1,
                                        pb: 1,
                                        "& .MuiFilledInput-root": {
                                            color: "#fff",
                                        },
                                    }}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlined"
                                    sx={{ mt: 3 }}
                                    disabled={!passwordsmatch}
                                    onClick={() => {
                                        UpdatePassword();
                                    }}
                                >
                                    Change Password
                                </Button>
                            </Box>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        Item Three
                    </CustomTabPanel>
                </Box>
                <Box sx={{ display: "none" }}>
                    <div
                        className={
                            "flex flex-col h-auto mt-2 w-full border border-slate-500 px-4 pb-6"
                        }
                    >
                        <div
                            onClick={() => {
                                setPasswordTab(!passwordTab);
                            }}
                            className={
                                "hover:bg-opacity-50 py-1 px-2 bg-[#04b57c] border-2 border-solid border-[#39ebb2] rounded-md text-white h-[42px]"
                            }
                        >
                            Change Password
                        </div>

                        <div>
                            <button
                                onClick={() => {

                                }}
                                className="px-6 py-2 mt-4 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            >
                                2FA management
                            </button>
                        </div>
                    </div>
                </Box>
            </section>
        </div>
    );
}
