import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-tooltip/dist/react-tooltip.css'
import { theme } from "./theme";
import {AppContextProvider} from "./contexts/appContext";
import {ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';
import { createTheme, MantineProvider } from '@mantine/core';
import {Provider} from "jotai";
import {DbContextProvider} from "./contexts/dbContext";

const myColor = [
    '#f4f4f6',
    '#e6e6e6',
    '#cacacb',
    '#acadb1',
    '#93949b',
    '#82848e',
    '#7a7c89',
    '#686a77',
    '#5c5e6b',
    '#4d5160'
];

const thememantis = createTheme({
    colors: {
        myColor,
    }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider>
        <ThemeProvider theme={theme}>
            <MantineProvider theme={thememantis} defaultColorScheme="dark">
                <BrowserRouter future={{
                    v7_startTransition: true,
                }}>
                        <AppContextProvider>
                            <DbContextProvider>
                                <App />
                                <Toaster />
                            </DbContextProvider>
                        </AppContextProvider>
                </BrowserRouter>
            </MantineProvider>
        </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
