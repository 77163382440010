import {atom} from 'jotai';

export const lobbyMessagesAtom = atom([]);
export const loadingMessagesAtom = atom(false);
export const loadingFriendsAtom = atom(false);
export const usersAtom = atom([]);
export const friendsAtom = atom([]);
export const userListAtom = atom([]);
export const allUsersAtom = atom([]);
export const myRequestsAtom = atom([]);
export const incRequestsAtom = atom([]);
export const readChannelAtom = atom(0);
export const selectedFriendAtom = atom(null);