import React from "react";
import { Anchor, Group, Progress, Table, Text } from '@mantine/core';
import {useAtomValue} from "jotai/index";
import {cardTotalStatsAtom} from "../atoms/statisticsAtoms";

export default function CardDeploys(props){
    const {hoverId, setHoverID} = props;
    const cardTotalStats = useAtomValue(cardTotalStatsAtom);

    function CardTable(props){
        const {list, text} = props;

        const totaldeploys = () => {
            let am = 0;
            for(let i = 0; i < list.length; i++){
                am += list[i].amount;
            }
            return am;
        };

        const rows = list.map((row) => {
            const positiveReviews = (row.amount / totaldeploys()) * 100;
            const negativeReviews = ((totaldeploys() - row.amount)  / totaldeploys()) * 100;

            return (
                <Table.Tr stickyHeader key={row.cardid}>
                    <Table.Td>
                        <Anchor component="button" fz="sm" onClick={() => setHoverID(row.cardid)}>
                            {row.cardName}
                        </Anchor>
                    </Table.Td>
                    <Table.Td>{row.amount}</Table.Td>
                    <Table.Td>
                        <Group justify="space-between">
                            <Text fz="xs" c="teal" fw={700}>
                                {positiveReviews.toFixed(0)}%
                            </Text>
                            <Text fz="xs" c="red" fw={700}>

                            </Text>
                        </Group>
                        <Progress.Root>
                            <Progress.Section
                                className={"progressSection"}
                                value={positiveReviews}
                                color="teal"
                            />

                            <Progress.Section
                                className={"progressSection"}
                                value={negativeReviews}
                                color="red"

                            />
                        </Progress.Root>
                    </Table.Td>
                </Table.Tr>
            );
        });

        return (
            <div
                className={"flex flex-col h-1/3 w-full lg:h-full lg:w-1/3 bg-[#183326C1] overflow-hidden rounded-xl border border-[#51b78c]"}>
                <div className={"flex flex-row h-10 w-full justify-center items-center"}>{text}</div>
                <div className={"flex flex-col h-full w-full overflow-y-auto"}>
                    <div className={"flex flex-col h-auto w-full"}>
                        <Table.ScrollContainer>
                            <Table verticalSpacing="xs">
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>Card Name</Table.Th>
                                        <Table.Th>Deploys</Table.Th>
                                        <Table.Th>{"Total " + totaldeploys()}</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>{rows}</Table.Tbody>
                            </Table>
                        </Table.ScrollContainer>
                    </div>
                </div>
            </div>)
    }

                        return (
                        <div
                            className={"flex flex-col lg:flex-row h-full w-full gap-3 overflow-hidden p-4 border-[#51b78c] border-t"}>
                            <CardTable list={cardTotalStats.unitDeployed.sort((a, b) =>
                                a.amount > b.amount ? -1 : 1)} text={"Units"}/>
                            <CardTable list={cardTotalStats.rcardsDeployed.sort((a, b) =>
                                a.amount > b.amount ? -1 : 1)} text={"Mana"}/>
                            <CardTable list={cardTotalStats.actionsDeployed.sort((a, b) =>
                                a.amount > b.amount ? -1 : 1)} text={"Actions"}/>
                        </div>
                        )
                        }