import React, {useEffect, useState} from "react";
import FeedPostModal from "./articlepost";
import Article from "./article";
import Button from "@mui/material/Button";
import { bordercolor } from "../theme";
import { ToggleButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {useAtom, useAtomValue} from "jotai/index";
import {editEnabledAtom, feedArticlesAtom, loadingNewsAtom, newsSyncAtom} from "../atoms/newsAtoms";
import {loadable} from "jotai/utils";
import {isAdminAtom, sessionAtom} from "../atoms/appAtoms";

export default function NewsFeed() {
    const session = useAtomValue(sessionAtom);
    const isAdmin = useAtomValue(isAdminAtom);
    const [posterOpen, setPosterOpen] = useState(false);
    const [editEnabled, setEditEnabled] = useAtom(editEnabledAtom);
    const [loadingNews, setLoadingNews] = useAtom(loadingNewsAtom);
    const [feedArticles, setFeedArticles] = useAtom(feedArticlesAtom);
    const loadableNewsSync = useAtomValue(loadable(newsSyncAtom));

    useEffect(() => {
        setLoadingNews(loadableNewsSync.state === 'loading');
        if (loadableNewsSync.state === 'hasData') {
            setFeedArticles(loadableNewsSync.data);
        }
    }, [loadableNewsSync, setFeedArticles, setLoadingNews]);

    return (
        <div className={"flex  text-[#51b78c] flex-col lg:w-[75%] w-full h-[95%] rounded-2xl overflow-hidden border " + bordercolor}>
        <main className="flex flex-col w-full h-full overflow-hidden">
            {/* Page header */}
            <div className="flex flex-row w-full h-16 justify-center bg-[#01130FF7] bg-opacity-40 items-center overflow-hidden">
                <div className="flex flex-row w-auto h-auto text-xl font-bold">Latest News</div>
            </div>

            <div className="flex flex-col w-full h-full overflow-x-hidden overflow-y-auto border-t border-b border-primary dark:border-slate-700 bg-black bg-opacity-20">
                <div className={"flex flex-col w-full h-auto"}>
                    <div className="flex flex-col w-full justify-center items-center">
                        {/* Posts */}
                        {loadingNews ?
                            <div
                                className=" xl:-translate-x-16 w-full h-full justify-center items-center flex flex-col">
                                <div className="flex flex-col gap-4 w-52">
                                    <div className="skeleton h-32 w-full"></div>
                                    <div className="skeleton h-4 w-28"></div>
                                    <div className="skeleton h-4 w-full"></div>
                                    <div className="skeleton h-4 w-full"></div>
                                </div>
                                <p>Loading Articles..</p>
                            </div> :
                            <div className="flex flex-col w-full h-auto justify-center items-center">
                                {session && isAdmin && posterOpen &&
                                    <FeedPostModal setPosterOpen={setPosterOpen}/>}
                                {feedArticles && feedArticles.map(((el,ind) =>
                                    <Article ind={ind} art={el} key={el.id}/>))}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={"flex flex-row w-full h-16 justify-between items-center bg-[#01130FF7] bg-opacity-40 "}>


                <div className="flex flex-row w-auto h-16 gap-4 justify-center overflow-hidden items-center pl-4">
                    {session && isAdmin &&
                        <Button size={"small"} className="text-white"
                                onClick={() => setPosterOpen(true)}>
                            <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                                <path
                                    d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"/>
                            </svg>
                            <span className="ml-2">Post Article</span>
                        </Button>}
                    {session && isAdmin &&
                        <div>
                            <ToggleButton
                                color="primary"
                                value="check"
                                selected={editEnabled}
                                onChange={() => {
                                    setEditEnabled(!editEnabled);
                                }}>
                                <CheckIcon />
                                Edit enabled
                            </ToggleButton>
                        </div>}
                </div>
                <div className="flex flex-row w-auto h-16 justify-end overflow-hidden items-center pr-4">
                    <ul className="flex flex-wrap -m-1">
                        <li className="m-1">
                            <button
                                className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-primary text-white duration-150 ease-in-out">
                                All
                            </button>
                        </li>
                        <li className="m-1">
                            <button
                                className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                                Announcements
                            </button>
                        </li>
                        <li className="m-1">
                            <button
                                className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                                Bug Fixes
                            </button>
                        </li>
                        <li className="m-1">
                            <button
                                className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                                Patch Notes
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </main>
    </div>
    );
}