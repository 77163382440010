import {useSocial} from "../hooks/useSocial";
import {Autocomplete, Checkbox, ListItemText, OutlinedInput, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useAtom, useAtomValue} from "jotai/index";
import {allUsersAtom} from "../atoms/socialAtoms";
import {userIdAtom} from "../atoms/appAtoms";

export default function Requests(props) {
    const { UserData, myRequests, incRequests} = useSocial();
    const allUsers = useAtomValue(allUsersAtom);
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        if(userList.length !== allUsers.length){
            let ls = [];
            for(let i = 0; i < allUsers.length; i++){
                ls.push({ value: allUsers[i].user_id, label: allUsers[i].username });
            }
            setUserList(ls);
        }
    }, [userList, allUsers])

    async function SendRequest() {
        const {error} = await supabase.from('friends').insert({
            friendid: value.user_id,
            status: "request"
        });
        if(error){
            toast.error('Failed to send friend request.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Friend request to ' + value.username + ' successfully sent.', {
            duration: 4000,
            position: 'bottom-right',
        });
        setValue(null);
        setInputValue("");
    }

    async function AcceptRequest(friendid){
        const {error} = await supabase.from('friends')
            .update({status: "accepted"})
            .eq("id", friendid)
    }

    async function DeclineRequest(friendid){
        const {error} = await supabase.from('friends')
            .update({status: "declined"})
            .eq("id", friendid)
    }

    async function CancelRequest(friendid) {
        const {error} = await supabase.from('friends')
            .delete()
            .eq("id", friendid)

        if(error){
            toast.error('Failed to cancel friend request.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Friend request successfully cancelled.', {
            duration: 4000,
            position: 'bottom-right',
        });
        setValue(null);
        setInputValue("");
    }

    return (
        <div className="flex flex-col w-full h-full overflow-hidden">
            <div className={"flex flex-row w-full h-16 justify-start items-center border-t border-[#717171FF] bg-white bg-opacity-10 overflow-hidden"}>
                <div className={"flex flex-row w-2/3 h-8 justify-center items-center p-2"}>
                    <Autocomplete
                        id="requestusers"
                        options={userList}
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        sx={{width: "100%"}}
                        color={"primary"}
                        renderInput={(params) => <TextField {...params} label="Select User"/>}
                    />
                </div>
                {value !== null && <Button onClick={() =>  { SendRequest(); }} className={"w-1/3 btn-ghost"}>Send Friend Request</Button>}
            </div>

            <div className={"flex flex-col h-full w-full overflow-hidden"}>
                <div className={"flex flex-col w-full h-1/2 overflow-y-auto"}>
                    <div className={"flex flex-row h-10 w-full text-md justify-center border-b border-[#717171FF] items-center"}>Active Incoming Requests</div>
                    {incRequests && incRequests.map((el, ind) => (
                        <div key={ind} className={"flex flex-row h-10 w-full justify-around items-center border-b  border-[#717171FF] bg-slate-300 bg-opacity-20 hover:bg-opacity-10"}>
                            {UserData(el.friendid).username}
                            {el.status === "request" ? <>
                                <Button onClick={() => { AcceptRequest(el.id) }} className={"btn-error w-auto h-10 "}>Accept</Button>
                                <Button onClick={() => { DeclineRequest(el.id) }} className={"btn-error w-auto h-10 "}>Decline</Button>
                            </> :
                                <div className={"flex flex-row w-auto px-4 h-10"}>Declined</div>}
                        </div>
                    ))}
                </div>
                <div className={"flex flex-col w-full h-1/2 overflow-y-auto border-t border-[#717171FF]"}>
                    <div className={"flex flex-row h-10 w-full text-md justify-center items-center border-b border-[#717171FF]"}>Active Outgoing Requests</div>
                    {myRequests && myRequests.map((el, ind) => (
                        <div key={ind} className={"flex flex-row h-10 w-full justify-around items-center border-b border-[#717171FF] bg-slate-300 bg-opacity-20 hover:bg-opacity-10"}>
                            {UserData(el.friendid).username}
                            <Button onClick={() => { CancelRequest(el.id) }} className={"btn-error w-auto h-10 "}>{el.status === "request" ? "Cancel" : "Remove"}</Button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}