import {Card} from "./cardentries";
import React from "react";
import { ScrollArea} from "@mantine/core";
import {useAtomValue} from "jotai/index";
import {cardsPerPageAtom, currentPageAtom} from "../atoms/deckBuilderAtoms";
import {cardsOnlyAtom} from "../atoms/appAtoms";

export const CardBox = (props) => {
    const {DisplayCards} = props;
    const cardsOnly = useAtomValue(cardsOnlyAtom);
    const currentPage = useAtomValue(currentPageAtom);
    const cardsPerPage = useAtomValue(cardsPerPageAtom);
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;

    return (
            <div id="container" className={`flex flex-col h-full w-full mb-10 lg:mb-0 overflow-hidden`}>
                <ScrollArea w={"100%"} h={"100%"}>
                    <div className={"flex flex-wrap h-auto w-full gap-1.5 my-auto justify-center items-center py-2"}>
                        {DisplayCards() && DisplayCards().map((card, index) =>
                            <Card
                                vis={index >= startIndex && index < endIndex}
                                key={card.Id}
                                cardsOnly={cardsOnly}
                                card={card}
                                type={card.type}
                            />
                        )}
                    </div>
                </ScrollArea>
            </div>
    )
}